import React, { useState, useEffect } from "react";
import TablePagination from "../TablePagination";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import { Link, useNavigate } from "react-router-dom";
import { useCommonDispatch, useCommonState } from "../../stores/common";
import Swal from "sweetalert2";
import { removeLocaUser } from "../../apis/localUsers";

const UsersTable = (props) => {

    const commonStore = useCommonState()
    const dispatch = useCommonDispatch()
    const navigate = useNavigate()

    const { users, totalPages, totalCount, currentPage, onPageChanged, loading } = props;

    const handleNavigate = (user, action = 'view') => {
        navigate(`/dashboard/manage-users/${action}/${user._id}`)
    }

    const handleDeleteUser = async ( user ) => {
        const { isConfirmed } = await Swal.fire({
            title: `Do you want to delete user "${user.fullName}"?`,
            showCancelButton: true,
            confirmButtonText: "Delete"
        })
        
        if ( isConfirmed ) {
            const status = await removeLocaUser({ id: user._id })
            if ( status.success ) {
                Swal.fire({
                    icon: "success",
                    text: "User has been deleted!",
                    willClose: () => {
                        navigate(0)
                    }
                })
            }
            else {
                Swal.fire({
                    icon: "error",
                    text: "Something went wrong! Please try again later!",
                })
            }
        }
    }

    return (
        <div className="table-responsive">

            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th width="5%" className="text-align-center">No</th>
                        <th width="15%" className="text-align-center">User ID</th>
                        <th width="15%" className="text-align-center">Full Name</th>
                        <th width="15%" className="text-align-center">Role</th>
                        <th width="10%" className="text-align-center">Created At</th>
                        <th width="10%" className="text-align-center">Updated At</th>
                        {/* <th width="10%" className="text-align-center">NonMemberId</th>
                        <th width="10%" className="text-align-center">ReferenceCheckId</th> */}
                        <th width="10%" className="text-align-center">Action</th>
                    </tr>
                </thead>
                {users && users.length > 0 && (

                    <tbody>
                        {users.map((user, i) => {
                            return (
                                <tr key={i} className={``}>
                                    <td className="text-align-center">{i + 1}</td>
                                    <td className="text-align-center">
                                        <a onClick={() => handleNavigate(user, 'edit')} className={`cursor-pointer item item-${user.status}`}>
                                            {user.userId}
                                        </a>
                                    </td>
                                    <td className="text-align-center">{user.fullName}</td>
                                    <td className="text-align-center">
                                        {user.roles.join(', ')}
                                    </td>
                                    <td className="text-align-center">
                                        {moment(user.createdAt).format("lll")}
                                    </td>
                                    <td className="text-align-center">
                                        {moment(user.updatedAt).format("lll")}
                                    </td>
                                    <td className="text-align-center">
                                        <a onClick={() => handleNavigate(user, 'edit')} className={`cursor-pointer item edit-user`}>
                                            Edit
                                        </a>
                                        <a onClick={() => handleDeleteUser(user)} className={`cursor-pointer item delete-user`}>
                                            Remove User
                                        </a>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                )}

                {loading && (
                    <tbody>
                        <tr>
                            <td colSpan={11}>
                                <div className="text-center">
                                    <Spinner size="sm" variant="info" animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </div>
                            </td>
                        </tr>
                    </tbody>)}

                {!loading && users.length === 0 && (
                    <tbody>
                        <tr>
                            <td colSpan={11}>
                                <div className="no-results-wrapper">
                                    <p className="mt-1 mb-1 text-center no-result">No data found</p>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                )}
            </table>

            {users && users.length > 0 &&
                <TablePagination
                    loading={loading}
                    totalPages={totalPages}
                    onPageChange={onPageChanged}
                    totalCount={totalCount}
                    currentPage={currentPage}
                />}
        </div>
    );
};

export default UsersTable;
