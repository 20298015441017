const caseStatuses = {
    submitted: {
        label: 'Submitted',
        value: 'submitted'
    },
    approved: {
        label: 'Approved',
        value: 'approved'
    },
    rejected: {
        label: 'Rejected',
        value: 'rejected'
    },
    endorsed: {
        label: 'Endorsed',
        value: 'endorsed'
    },
    discharged: {
        label: 'Discharged',
        value: 'discharged'
    }
}

export const mapCaseStatus = (status) => {
    if ( typeof caseStatuses[status] !== 'undefined' ) {
        return caseStatuses[ status ].label
    }
    return 'N/A'
}

export default caseStatuses