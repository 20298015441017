import apiConfig, { 
    getRequestHeaders
} from './base'

const { axiosInstance } = apiConfig

const baseApiURL = apiConfig.baseApiURL

/** 
 * Get all cases with pagination 
 **/
export const mobileLookup = async ( args = {} ) => {

    let result = {
        success: false
    }

    const requestHeaders = getRequestHeaders()

    try {
        const { data } = await axiosInstance.post(`${baseApiURL}/general/mobile-lookup/`, args, requestHeaders)
        result.data = data
        result.success = true
    }
    catch( e ) {
        result.message = e.message
    }

    return result
}