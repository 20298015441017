import apiConfig, { 
    getRequestHeaders
} from './base'

const { axiosInstance } = apiConfig

const baseApiURL = apiConfig.baseApiURL

/** 
 * Get all cases with pagination 
 **/
export const getAllReviewPeriods = async ( args ) => {

    let result = {
        success: false
    }

    const requestHeaders = getRequestHeaders()

    try {
        const { data } = await axiosInstance.get(`${baseApiURL}/general/review-periods/`, requestHeaders)
        result = data
    }
    catch( e ) {
        result.message = e.message
    }

    return result
}