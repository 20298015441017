import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'
import Select from 'react-select'

const BaseModal = (props) => {

    const {
        title
    } = props

    return (
        <>
            <Modal
                {...props}
            >
                <Modal.Header closeButton={false} className="d-block">
                    <h5 className="modal-title text-center">
                        <div className="text-center">{title}</div>
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    {props.children}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default BaseModal