import './App.css';
import {
    ssoValidateCode,
    getUserProfile,
    createUser
} from './sso';
import { rand, serialize } from './utils'
import { useEffect, useState } from 'react';
import router from './routes';
import FullScreenLoading from './components/FullScreenLoading';
import NoPermission from './pages/NoPermission'
import { useCommonDispatch } from './stores/common';
import OTPInput from './pages/OTPInput';
import { requestOTP } from './sso';
import 'react-datepicker/dist/react-datepicker.css'

const ssoUrl = process.env.REACT_APP_SSO_URL || ''

function App() {

    const [isAuthorized, setIsAuthorized] = useState( false )
    const [isOtpValidation, setIsOTPValidation] = useState( false )
    const [isValidating, setIsValidating] = useState( true )
    
    const [tokens, setTokens] = useState()
    const [userProfile, setUserProfile] = useState()

    const dispatch = useCommonDispatch()

    useEffect(() => {
        _validateUser()
    }, [])

    const _validateUser = async () => {
        const userLoggedIn = localStorage.getItem('nccAuthTokens')
        // Already logged in
        if ( userLoggedIn ) {
            setIsValidating( false )
            setIsAuthorized( true )
        }
        else {

            const params = new URLSearchParams( window.location.search )
            const authorizeCode = params.get('code')

            // Request SSO
            if ( !authorizeCode ) {
                const args = {
                    client_id: process.env.REACT_APP_SSO_CLIENT_ID,
                    // client_secret: process.env.REACT_APP_SSO_CLIENT_SECRET,
                    redirect_uri: process.env.REACT_APP_SSO_REDIRECT_URI,
                    response_type: process.env.REACT_APP_SSO_RESPONSE_TYPE,
                    scope: process.env.REACT_APP_SSO_SCOPE,
                    access_type: "offline",
                    state: rand(),
                    nonce: rand()
                }
                
                const buffer = `${ssoUrl}/connect/authorize?${serialize(args)}`
                return window.location.href = buffer
            }
            // Redirect URI with ?code
            else {
                const ssoTokens = await ssoValidateCode( authorizeCode )
                console.log('=ssoValidateCode=', ssoTokens)
                if ( !ssoTokens ) {
                    return _validateFalse()
                }
                else {

                    setTokens( ssoTokens )

                    const userProfile = await getUserProfile( ssoTokens )
                    
                    if ( !userProfile ) {
                        return _validateFalse()
                    }

                    setUserProfile( userProfile )

                    const user = await createUser( userProfile, ssoTokens.access_token )

                    if ( !user ) {
                        return _validateFalse()
                    }

                    dispatch({ type: 'NCC_USER_PROFILE', payload: userProfile })

                    if ( process.env.REACT_APP_ABORT_OTP === true || process.env.REACT_APP_ABORT_OTP === 'true' ) {

                        localStorage.setItem('nccAuthTokens', JSON.stringify(ssoTokens))
                        localStorage.setItem('nccUserProfile', JSON.stringify(userProfile))
                        // return window.location.href = '/dashboard/cases'
                        return window.location.reload()
                    }
                    else {

                        // Send OTP request
                        // * Commented in dev mode. Open when merge
                        await requestOTP( ssoTokens.access_token )

                        // OTP Validation
                        // * Commented in dev mode. Open when merge
                        setIsOTPValidation( true )
                    }
                }
            }
        }
        dispatch({ type: "NCC_INITIALIZING", payload: false })
        // TODO removee
    }

    // TODO remove
    // const _fakeValid = () => {
    //     setIsOTPValidation(true)
    //     setIsValidating(true)
    //     setIsAuthorized(false)
    //     setUserProfile(null)
    //     setTokens(null)
    // }

    const _validateFalse = () => {
        setIsValidating( false )
        setIsAuthorized( false )
    }

    return (
        <>
            {isValidating && !isOtpValidation && <FullScreenLoading />}
            {isValidating && isOtpValidation && <OTPInput tokens={tokens} userProfile={userProfile} />}
            {!isValidating && !isAuthorized && !isOtpValidation && <NoPermission />}
            {!isValidating && isAuthorized && <>{router}</>}
        </>
    );
}

export default App;
