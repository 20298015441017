import { useEffect, useState } from "react"
import ReactPaginate from "react-paginate"
import NextLabel from './NextLabel'
import PreviousLabel from './PreviousLabel'
import apiConfig from '../../apis/base'
import { Spinner } from 'react-bootstrap'
import './scss/_pagination.scss'

const TablePagination = (props) => {

    const { totalPages, totalCount, currentPage, loading } = props

    const [from, setFrom] = useState(0)
    const [to, setTo] = useState(0)

    useEffect(() => {
        setFrom( apiConfig.casesPerPage * (currentPage - 1) + 1 )
        const to = apiConfig.casesPerPage * currentPage
        setTo( to > totalCount ? totalCount : to )
    }, [currentPage])
    
    const handlePageChange = ( page ) => {
        props.onPageChange( page.selected + 1 )
    }
    
    return (
        <>
        {totalCount > 0 && 
            <nav className="ncc-pagination">
                <ReactPaginate
                    previousLabel={<PreviousLabel />}
                    nextLabel={<NextLabel />}
                    pageCount={totalPages}
                    breakLabel={'...'}
                    breakClassName={'page-link'}
                    containerClassName={`pagination ncc-pagination`}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    pageClassName={'page-item'}
                    previousClassName={'page-item'}
                    nextClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousLinkClassName={'page-link'}
                    nextLinkClassName={'page-link'}
                    onPageChange={handlePageChange}
                    forcePage={(currentPage - 1)}
                />
                <div className="dfl-r-jctr-alctr ncc-pt-px-10">
                    {loading ? 
                    <Spinner size="sm" variant="info" animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    : <>{from} - {to} of {totalCount} records</>}
                </div>
            </nav>
        }
        </>
    )
}

export default TablePagination

