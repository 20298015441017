import apiConfig, { 
    getRequestHeaders
} from './base'

const { axiosInstance } = apiConfig

const baseApiURL = apiConfig.baseApiURL

/** 
 * Get all cases with pagination 
 **/
export const getPermissions = async ( screen ) => {

    let result = {
        success: false
    }

    const requestHeaders = getRequestHeaders()

    try {
        const { data } = await axiosInstance.get(`${baseApiURL}/permissions/${screen}`, requestHeaders)
        return data
    }
    catch( e ) {
        result.message = e.message
    }

    return result
}