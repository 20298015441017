import apiConfig, {
    getRequestHeaders
} from './base'
import {
    serialize
} from '../utils'

const baseApiURL = apiConfig.baseApiURL
const casesPerPage = apiConfig.casesPerPage

const { axiosInstance } = apiConfig

/** 
 * Get all cases with pagination 
 **/
export const getCases = async (args) => {

    let result = {
        success: false
    }

    const { page, keyword, role, ministryFilter, startDateFilter, endDateFilter } = args

    const requestHeaders = getRequestHeaders()

    let queryArgs = {
        pageSize: casesPerPage,
        current: page,
        search: keyword,
        role: role,
    }
    if (ministryFilter?.value && ministryFilter?.value !== 'undefine') {
        queryArgs = { ...queryArgs, ministryId: ministryFilter?.value }
    }

    if (startDateFilter && startDateFilter !== 'undefine') {
        queryArgs = { ...queryArgs, startDate: startDateFilter }
    }

    if (endDateFilter && endDateFilter !== 'undefine') {
        queryArgs = { ...queryArgs, endDate: endDateFilter }
    }

    try {
        const { data } = await axiosInstance.get(`${baseApiURL}/cases/?${serialize(queryArgs)}`, requestHeaders)
        result = data
    }
    catch (e) {
        result.message = e.message
    }

    return result
}

export const getCaseDetails = async (param) => {

    const { id, current_role } = param

    let queryArgs = {
        current_role: current_role.toLowerCase(),
    }

    let result = {
        success: false
    }

    const requestHeaders = getRequestHeaders()

    if (current_role) {
        requestHeaders.headers.x_current_role = current_role
    }

    try {
        const { data } = await axiosInstance.get(`${baseApiURL}/cases/${id}?&${serialize(queryArgs)}`, requestHeaders)
        result = data
    }
    catch (e) {
        result.message = e.message
    }

    return result
}

export const addCase = async (args) => {

    let result = {
        success: false
    }

    const requestHeaders = getRequestHeaders()

    try {
        const { data } = await axiosInstance.post(`${baseApiURL}/cases`, args, requestHeaders)
        result = data
    }
    catch (error) {
        result.message = error?.response?.data?.error?.message || error.message;
    }

    return result;
}

export const editCase = async (id, args) => {

    let result = {
        success: false
    }

    const requestHeaders = getRequestHeaders()
    // requestHeaders.headers["Content-Type"] = "multipart/form-data"

    try {
        const { data } = await axiosInstance.patch(`${baseApiURL}/cases/${id}`, args, requestHeaders)
        result = data
    }
    catch (error) {
        result.message = error?.response?.data?.error?.message || error.message;
    }

    return result
}

export const disChargeCase = async (id, args) => {
    let result = {
        success: false
    }

    const requestHeaders = getRequestHeaders()

    try {
        const { data } = await axiosInstance.patch(`${baseApiURL}/cases/discharge/${id}`, args, requestHeaders)
        result = data
    } catch (error) {
        result.message = error?.response?.data?.error?.message || error.message;
    }
    return result
}

export const checkDuplicatedPhone = async (countryCode, phoneNumber) => {

    let result = {
        success: false
    }

    const requestHeaders = getRequestHeaders()

    let queryArgs = {
        countryCode: countryCode,
        phoneNumber: phoneNumber
    }

    try {

        const { data } = await axiosInstance.get(`${baseApiURL}/cases/check-dupplicated-phone/?${serialize(queryArgs)}`, requestHeaders)

        return data

    } catch (error) {
        result.message = error?.response?.data?.error?.message || error.message;
        return null;
    }

}

export const extendCase = async (id, args) => {
    let result = {
        success: false
    }

    const requestHeaders = getRequestHeaders()

    try {
        const { data } = await axiosInstance.patch(`${baseApiURL}/cases/extend/${id}`, args, requestHeaders)
        result = data
    } catch (error) {
        result.message = error?.response?.data?.error?.message || error.message;
    }
    return result
}

export const updateCaseStatus = async (id, status) => {

    let result = {
        success: false
    }

    const requestHeaders = getRequestHeaders()

    try {
        const { data } = await axiosInstance.patch(`${baseApiURL}/cases/${id}`, { status: status }, requestHeaders)
        result = data
    }
    catch (error) {
        result.message = error?.response?.data?.error?.message || error.message;
    }

    return result
}

export const deleteCase = async (id) => {

    let result = {
        success: false
    }

    const requestHeaders = getRequestHeaders()

    try {
        const { data } = await axiosInstance.delete(`${baseApiURL}/cases/${id}`, requestHeaders)
        result = data
    }
    catch (error) {
        result.message = error?.response?.data?.error?.message || error.message;
    }

    return result;
}