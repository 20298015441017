import { useEffect, useState } from "react"
import { useCommonDispatch, useCommonState } from "../stores/common"
import HeaderBar from "../components/HeaderBar"
import FullScreenLoading from '../components/FullScreenLoading'
import { Link, useLocation, useNavigate } from "react-router-dom"
import { getPermissions } from '../apis/permissions'
import { hasPermissions } from "../utils"
import { getUserByUserId } from "../apis/localUsers"
import NoPermission from "../pages/NoPermission"

const MainLayout = (props) => {

    const navigate = useNavigate()

    const { allowedPerms } = props

    const commonStore = useCommonState()
    const dispatch = useCommonDispatch()
    const [breadcrumbText, setBreadcrumbText] = useState('')
    const location = useLocation()

    const isAddNewUserPage = location.pathname.includes('add-new-user');
    const isManageUsersPage = location.pathname.includes('manage-users');
    const isSearchCase = location.pathname.includes('search');

    const [canGoBack, setCanGoBack] = useState(false)
    const [perms, setPerms] = useState(false)
    const [allowed, setAllowed] = useState()
    const [loadingPermissions, setLoadingPermissions] = useState(true)

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        let routers = location.pathname.replace('/dashboard/', '')
        routers = routers.split('/')
        if ((typeof routers[1] !== 'undefined' && routers[1] !== '') || routers[0] === 'manage-users'|| routers[0] === 'search') {
            setCanGoBack(true)
        }
        /**
         * Get permissions
         */
        const _getPermissions = async () => {
            setLoadingPermissions(true)
            const rcPerms = await getPermissions('NCCRC ReferenceCheck')
            // const globalPerms = await getPermissions('NCCRC')
            // const rcPerms = {"success":true,"data":{"ModuleFeatures":["View","Approve"]}}
            if (rcPerms.success && rcPerms?.data?.ModuleFeatures?.length > 0) {
                dispatch({ type: 'PERMISSIONS', payload: rcPerms?.data?.ModuleFeatures })
                parseRole(rcPerms?.data?.ModuleFeatures)
                setPerms(rcPerms?.data?.ModuleFeatures)
            }
            else {
                if (process.env.REACT_APP_FAKE_PERMS) {
                    try {
                        const perms = JSON.parse(process.env.REACT_APP_FAKE_PERMS)
                        dispatch({ type: 'PERMISSIONS', payload: perms })
                        parseRole(perms)
                        setPerms(perms)
                    }
                    catch (error) {
                        console.log('=JSON-parse-ERROR=', error)
                        setPerms([])
                    }
                }
                else {
                    parseRole([])
                    setPerms([])
                }
            }
            setLoadingPermissions(false)
        }
        _getPermissions()

        if (isAddNewUserPage) {
            setBreadcrumbText('Add New User')
        }
        else if (isManageUsersPage) {
            setBreadcrumbText('Manage Users')
        } 
        else if ( isSearchCase ) {
            setBreadcrumbText('Search')
        }
        else {
            setBreadcrumbText('')
        }
        
    }, [])

    useEffect(() => {
        if ( commonStore.currentRole === 'Viewer' ) {
            let routers = location.pathname.replace('/dashboard/', '')
            routers = routers.split('/')
            if ( routers[0] !== 'search' ) {
                navigate( '/dashboard/search' )
            }
        }
    }, [commonStore.currentRole])

    useEffect(() => {
        
        if (perms === false) {
            return
        }
        
        if (Array.isArray(perms) && perms.length > 0 && hasPermissions(perms, allowedPerms)) {
            setAllowed(true)
        }
        else {
            setAllowed(false)
        }
    }, [perms])

    const parseRole = async (perms) => {

        /**
         * Parse role
         */
        let role = ''
        if (perms.indexOf('View') !== -1
            && perms.indexOf('Submit') !== -1
        ) {
            role = 'Requestor'
        }
        if (perms.indexOf('View') !== -1
            && perms.indexOf('Approve') !== -1
        ) {
            role = 'Approver'
        }
        if (perms.indexOf('Admin') !== -1) {
            role = 'Admin'
        }

        // Check roles from our managed users list
        if ( role !== 'Admin' ) {
            const userProfile = JSON.parse(localStorage.getItem('nccUserProfile'))
            const localUserExist = await getUserByUserId( userProfile?.userId )
            if ( localUserExist?.success ) {
                const user = localUserExist?.data
                role = Array.isArray(user?.roles) ? user?.roles[0] : user?.roles
            }
            else {
                navigate('/no-permission/')
            }
        }
        
        dispatch({ type: 'CURRENT_ROLE', payload: role })
    }

    return (
        <div className="ncc-pos-relative">
            {commonStore.isInitializing ?
                <FullScreenLoading />
                :
                <>
                    <HeaderBar canGoBack={canGoBack} />

                    {loadingPermissions ?
                        <FullScreenLoading inside={true} />
                        :
                        <>

                            {canGoBack && allowed &&
                                <div className="container-lg mt-3 mb-3">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item" aria-current="page">
                                                <Link to={'/dashboard/cases'}>Home</Link>
                                            </li>
                                            <li className="breadcrumb-item active" aria-current="page">{breadcrumbText !== '' ? breadcrumbText : 'Case Details'}</li>
                                        </ol>
                                    </nav>
                                </div>}

                            <div className="container-lg">
                                {allowed && props.children}

                                {allowed === false &&
                                    <h1 className="mt-5 mb-5 font-weight-bold text-center">
                                        <NoPermission/>
                                    </h1>}
                            </div>
                        </>}
                </>
            }
        </div>
    )
}

MainLayout.defaultProps = {
    allowedPerms: []
}

export default MainLayout