import React, { useState, useEffect } from 'react';
import { useCommonState } from '../../stores/common';
import OtpInput from 'react18-input-otp';
import { requestOTP, verifyOTP } from '../../sso';
import Spinner from 'react-bootstrap/Spinner';
import '../../scss/_general.scss'
const countDownTime = 30

const OTPInput = (props) => {

    const { tokens, userProfile } = props

    const commonStore = useCommonState()

    const [otp, setOtp] = useState('')
    const [valid, setValid] = useState( false )
    const [countDown, setCountDown] = useState(countDownTime)
    const [sending, setSending] = useState(false)

    const [error, setError] = useState(false)

    useEffect(() => {
        if (countDown > 0) {
            setTimeout(() => {
                setCountDown((prev) => prev - 1)
            }, 1000)
        }
    }, [countDown])

    useEffect(() => {
        if ( otp.length === 6 ) {
            setValid( true )
        }
    }, [otp])
    
    const handleChange = (otp) => {
        setOtp(otp)
    }

    const resend = async () => {
        setSending( true )
        try {
            await requestOTP( tokens.access_token )
            setCountDown(countDownTime)
        }
        catch(error) {}
        setSending( false )
    }

    const handleSubmit = async () => {
        try {
            const data = await verifyOTP( otp, tokens.access_token )
            if ( data && data.message === true ) {
                localStorage.setItem('nccAuthTokens', JSON.stringify(tokens))
                localStorage.setItem('nccUserProfile', JSON.stringify(userProfile))
                return window.location.href = '/#/dashboard/cases'
                // navigate('dashboard/cases')
            }
        }
        catch(error) {
            console.error('Wrong OTP')
        }

        setError( true )
    }

    return (
        <div className="d-flex justify-content-center align-items-center vh-100 ncc-otp-input">
            <div className="ncc-min-w-px-563 d-flex justify-content-center align-items-center flex-column">
                <div className="p-5">
                    <span className="ncc-fw-600 ncc-fs-50">Enter OTP</span>
                </div>
                <div className="ncc-p-5 text-align-center">
                    <span className="ncc-fs-22">The One-Time-Password (OTP) has been sent to {commonStore.currentUserProfile?.phoneNumber}</span>
                </div>
                <OtpInput
                    value={otp}
                    onChange={handleChange}
                    numInputs={6}
                    separator={<span>-</span>}
                    onSubmit={() => handleSubmit(otp)}
                    isInputNum={true}
                    autoComplete={true}
                    hasErrored={true}
                    shouldAutoFocus={true}
                    containerStyle={`ncc-p-5`}
                />
                {error && <div className="feedback-invalid dfl-r-jctr">
                    Wrong OTP
                </div>}

                <div className="dfl-r-spbtw ncc-w-100">
                    <div className="dfl-r-jctr-alctr otp-count-down ncc-w-px-200">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clock" viewBox="0 0 16 16">
                            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                        </svg>
                        <div className="dfl ncc-pl-3"><span className="ncc-pl-3 dfl">{countDown === 0 ? '00:00' : `00:${countDown}`}<span className="ncc-pl-px-10">seconds</span></span></div>
                    </div>
                    <div>
                        <span>
                            <a onClick={resend} className={`btn ${countDown > 0 ? 'disabled' : ''}`} href="#">
                                Resend OTP &nbsp;
                                {sending &&
                                    <Spinner size="sm" variant="info" animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                }
                            </a>
                        </span>
                    </div>
                </div>
                <div className="dfl-c-jctr-alctr ncc-p-5 ncc-w-100">
                    <button onClick={() => handleSubmit(otp)} className={`ncc-w-100 otp-button-filled btn ${valid ? '' : 'disabled'}`}>Submit</button>
                    <button className="text-center ncc-w-100 btn otp-button-transparent" href="/">Back</button>
                </div>
                <div className="dfl-r-spbtw ncc-w-100">
                    <div>
                        <span><a href="#">Did not receive OTP?</a></span>
                    </div>
                    <div>
                        <span><a href="#">Contact us here</a></span>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default OTPInput