
const FullScreenLoading = ({ inside = false }) => {
    return ( 
        <div className={`spinner ${inside ? 'inside' : ''}`}>
            <div className="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default FullScreenLoading