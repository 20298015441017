import Swal from 'sweetalert2'
import MainLayout from '../../containers/MainLayout'
import { addCase } from '../../apis/cases'
import { uploadMedia } from '../../apis/media';
import CaseForm from '../../components/CaseForm';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useCommonState } from '../../stores/common';

const AddCase = () => {

    const allowedPerms = ['Submit']

    const commonStore = useCommonState()

    const [isSubmitted, setIsSubmitted] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [role, setRole] = useState('')

    useEffect(() => {
        setRole(commonStore.currentRole)
    }, [commonStore.currentRole])

    const uploadFiles = async (file) => {
        const formData = new FormData()
        formData.append('file', file)
        return await uploadMedia(formData)
    }

    /**
     * Action only when all datas are v
     * @param {array} data 
     */
    const onSubmit = async (params) => {

        if (isLoading) {
            return
        }

        setIsLoading(true)

        let documentsUpload,
            photosUpload;
        if (params?.documents?.[0]) {
            documentsUpload = await uploadFiles(params?.documents?.[0])
        }
        if (params?.photos?.[0]) {
            photosUpload = await uploadFiles(params.photos[0])
        }

        if (documentsUpload && !documentsUpload.success) {
            setIsLoading(false)
            return Swal.fire('', 'Could not upload document. Please try again!', 'error')
        }

        if (photosUpload && !photosUpload.success) {
            setIsLoading(false)
            return Swal.fire('', 'Could not upload photo. Please try again!', 'error')
        }

        const { file, photos, ...filterParams } = params
        const result = await addCase({
            ...filterParams,
            ...{
                documents: documentsUpload ? [documentsUpload.data._id.toString()] : [],
                photos: photosUpload ? [photosUpload.data._id.toString()] : [],
            },
            ...{
                role: role,
                permissions: commonStore.permissions
            }
        })

        setIsLoading(false)

        if (result.success) {
            setIsSubmitted(true)
        }
        else {
            Swal.fire('', result.message, 'error')
        }
    }

    return (
        <>
            <MainLayout allowedPerms={allowedPerms}>
                <div className="ncc-pos-relative">
                    {!isSubmitted
                        ?
                        <div className="container pb-5">
                            <h1 className="mb-5">Submit New Case</h1>
                            <CaseForm onSubmit={onSubmit} loading={isLoading} createNewCase={true} viewAction='create' />
                        </div>
                        :
                        <div className="case-submitted pb-5">
                            <h3 className="mb-3">Case Submitted</h3>
                            <p>This new case has been submitted for approval. <br />
                                You will receive an email notification once this case has been reviewed.</p>

                            <Link
                                to={'/'}
                                className="btn btn-success"
                            >
                                Return to Home
                            </Link>
                        </div>
                    }
                </div>
            </MainLayout>
        </>
    )
}

export default AddCase