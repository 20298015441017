import { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import { useNavigate, useParams } from 'react-router-dom';
import MainLayout from '../../containers/MainLayout'
import { editCase, getCaseDetails } from '../../apis/cases'
// import { uploadMedia } from '../../apis/media';
import CaseForm from '../../components/CaseForm';
import moment from 'moment';
import { mapCaseStatus } from '../../utils/caseStatus';
import { capitalizeFirstLetter } from '../../utils';
// import { Link } from 'react-router-dom';
import { useCommonState } from "../../stores/common";

const ViewCase = () => {

  
    const { id } = useParams()

    const [caseData, setCaseData] = useState(null)

    const [isLoading, setIsLoading] = useState(false)
    
    const commonStore = useCommonState()
    const navigate = useNavigate()
    
    useEffect(() => {
        
        const _getCaseDetails = async () => {
            setIsLoading(true)
            const _case = await getCaseDetails( {id: id, current_role: commonStore?.currentRole} )
            if ( _case.success ) {
                setIsLoading(false)
                setCaseData( _case.data )
            }
            else{
                setIsLoading(false)
                navigate('/no-permission/')
            }
        }

        _getCaseDetails()
    }, [])

    return (
        <>
            <MainLayout>
                <div className="ncc-pos-relative">
                    <div className="container pb-5">
                        <h1 className="mb-5">Case Details</h1>
                        <div className="row">
                            <div className="col-md-9">
                                <CaseForm caseData={caseData} loading={isLoading} viewOnly={true} viewAction='view'/>
                            </div>

                            <div className="col-md-3">
                                <div className="case-notes-wrapper border p-2">
                                    <div className="d-flex">
                                        <h5>Status: &nbsp;</h5>
                                        <h5 className="text-center ml-3">{mapCaseStatus(caseData?.status)}</h5>
                                    </div>
                                    <h5>Case Notes</h5>
                                    <ul className="list-group">
                                        {caseData && caseData?.trackings.length > 0 && caseData?.trackings.map( (tracking, i) => {
                                            return (
                                                <li key={i} className="list-group-item">
                                                    {moment(tracking.createdAt).format('LLL')} <br/>
                                                    Level of Action: {tracking.rating}<br />
                                                    <strong>{capitalizeFirstLetter(tracking.approve_type)} by {tracking.approve_user.fullName}</strong>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MainLayout>
        </>
    )
}

export default ViewCase