const CalendarSvg = (props) => {
  const {className} = props;

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g opacity="0.56">
        <path
          d="M22.7707 14.6458C23.346 14.6458 23.8123 14.1795 23.8123 13.6042C23.8123 13.0289 23.346 12.5625 22.7707 12.5625C22.1954 12.5625 21.729 13.0289 21.729 13.6042C21.729 14.1795 22.1954 14.6458 22.7707 14.6458Z"
          fill="white"
        />
        <path
          d="M25.1665 4.74996H23.8123V3.70829C23.8123 3.13298 23.346 2.66663 22.7707 2.66663C22.1954 2.66663 21.729 3.13298 21.729 3.70829V4.74996H16.9894V3.70829C16.9894 3.13298 16.5231 2.66663 15.9478 2.66663C15.3724 2.66663 14.9061 3.13298 14.9061 3.70829V4.74996H10.2186V3.70829C10.2186 3.13298 9.75223 2.66663 9.17692 2.66663C8.60161 2.66663 8.13525 3.13298 8.13525 3.70829V4.74996H6.83317C4.53567 4.74996 2.6665 6.61913 2.6665 8.91663V25.1666C2.6665 27.4641 4.53567 29.3333 6.83317 29.3333H14.8019C15.3772 29.3333 15.8436 28.8669 15.8436 28.2916C15.8436 27.7163 15.3772 27.25 14.8019 27.25H6.83317C5.68442 27.25 4.74984 26.3154 4.74984 25.1666V8.91663C4.74984 7.76788 5.68442 6.83329 6.83317 6.83329H8.13525V7.87496C8.13525 8.45027 8.60161 8.91663 9.17692 8.91663C9.75223 8.91663 10.2186 8.45027 10.2186 7.87496V6.83329H14.9061V7.87496C14.9061 8.45027 15.3724 8.91663 15.9478 8.91663C16.5231 8.91663 16.9894 8.45027 16.9894 7.87496V6.83329H21.729V7.87496C21.729 8.45027 22.1954 8.91663 22.7707 8.91663C23.346 8.91663 23.8123 8.45027 23.8123 7.87496V6.83329H25.1665C26.3153 6.83329 27.2498 7.76788 27.2498 8.91663V14.8541C27.2498 15.4294 27.7162 15.8958 28.2915 15.8958C28.8668 15.8958 29.3332 15.4294 29.3332 14.8541V8.91663C29.3332 6.61913 27.464 4.74996 25.1665 4.74996Z"
          fill="#1f1e1e"
        />
        <path
          d="M23.0311 16.7291C19.5561 16.7291 16.729 19.5562 16.729 23.0312C16.729 26.5062 19.5561 29.3333 23.0311 29.3333C26.5061 29.3333 29.3332 26.5062 29.3332 23.0312C29.3332 19.5562 26.5061 16.7291 23.0311 16.7291ZM23.0311 27.25C20.7049 27.25 18.8123 25.3575 18.8123 23.0312C18.8123 20.705 20.7049 18.8125 23.0311 18.8125C25.3573 18.8125 27.2498 20.705 27.2498 23.0312C27.2498 25.3575 25.3573 27.25 23.0311 27.25Z"
          fill="#1f1e1e"
        />
        <path
          d="M24.5413 21.9895H24.0726V20.8958C24.0726 20.3205 23.6062 19.8541 23.0309 19.8541C22.4556 19.8541 21.9893 20.3205 21.9893 20.8958V23.0312C21.9893 23.6065 22.4556 24.0729 23.0309 24.0729H24.5413C25.1167 24.0729 25.583 23.6065 25.583 23.0312C25.583 22.4559 25.1167 21.9895 24.5413 21.9895Z"
          fill="#1f1e1e"
        />
        <path
          d="M18.2394 14.6458C18.8147 14.6458 19.2811 14.1795 19.2811 13.6042C19.2811 13.0289 18.8147 12.5625 18.2394 12.5625C17.6641 12.5625 17.1978 13.0289 17.1978 13.6042C17.1978 14.1795 17.6641 14.6458 18.2394 14.6458Z"
          fill="#1f1e1e"
        />
        <path
          d="M13.7082 19.1771C14.2835 19.1771 14.7498 18.7107 14.7498 18.1354C14.7498 17.5601 14.2835 17.0938 13.7082 17.0938C13.1329 17.0938 12.6665 17.5601 12.6665 18.1354C12.6665 18.7107 13.1329 19.1771 13.7082 19.1771Z"
          fill="#1f1e1e"
        />
        <path
          d="M9.17692 14.6458C9.75222 14.6458 10.2186 14.1795 10.2186 13.6042C10.2186 13.0289 9.75222 12.5625 9.17692 12.5625C8.60162 12.5625 8.13525 13.0289 8.13525 13.6042C8.13525 14.1795 8.60162 14.6458 9.17692 14.6458Z"
          fill="#1f1e1e"
        />
        <path
          d="M9.17692 19.1771C9.75222 19.1771 10.2186 18.7107 10.2186 18.1354C10.2186 17.5601 9.75222 17.0938 9.17692 17.0938C8.60162 17.0938 8.13525 17.5601 8.13525 18.1354C8.13525 18.7107 8.60162 19.1771 9.17692 19.1771Z"
          fill="#1f1e1e"
        />
        <path
          d="M9.17692 23.7083C9.75222 23.7083 10.2186 23.242 10.2186 22.6667C10.2186 22.0914 9.75222 21.625 9.17692 21.625C8.60162 21.625 8.13525 22.0914 8.13525 22.6667C8.13525 23.242 8.60162 23.7083 9.17692 23.7083Z"
          fill="#1f1e1e"
        />
        <path
          d="M13.7082 23.7083C14.2835 23.7083 14.7498 23.242 14.7498 22.6667C14.7498 22.0914 14.2835 21.625 13.7082 21.625C13.1329 21.625 12.6665 22.0914 12.6665 22.6667C12.6665 23.242 13.1329 23.7083 13.7082 23.7083Z"
          fill="#1f1e1e"
        />
        <path
          d="M13.7082 14.6458C14.2835 14.6458 14.7498 14.1795 14.7498 13.6042C14.7498 13.0289 14.2835 12.5625 13.7082 12.5625C13.1329 12.5625 12.6665 13.0289 12.6665 13.6042C12.6665 14.1795 13.1329 14.6458 13.7082 14.6458Z"
          fill="#1f1e1e"
        />
      </g>
    </svg>
  );
};

export default CalendarSvg