import { useNavigate } from "react-router-dom";
import { useCommonDispatch, useCommonState } from '../stores/common';
import { useEffect, useState } from 'react';
import { logout } from '../sso';

const HeaderBar = (props) => {

    const { canGoBack } = props

    const commonStore = useCommonState()
    const dispatch = useCommonDispatch()
    let navigate = useNavigate()

    useEffect(() => {
        dispatchUserProfile()
        dispatchSsoTokens()
    }, [])

    const dispatchUserProfile = () => {
        try {
            const userProfile = JSON.parse(localStorage.getItem('nccUserProfile'))
            dispatch({ type: "NCC_USER_PROFILE", payload: userProfile })
        }
        catch(err) {
            console.error('Get user profile error', err)
        }
    }

    const dispatchSsoTokens = () =>{
        try{
            const ssoTokens = JSON.parse(localStorage.getItem('nccAuthTokens'))
            dispatch({type: 'SSO_TOKENS', payload: ssoTokens })
        }
        catch(err){
            console.error('Get ssoTokens error', err)
        }
    }


    const handleGoBack = () => {
        navigate(-1)
    }
    
    return (
        <div className="header p-4">
            <div className="dfl top-navigation">
                {/* {canGoBack && 
                    <svg onClick={() => handleGoBack()} className="ncc-w-px-25" role={`button`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg>
                } */}
                <div className="ncc-fw-800">NCC Reference System</div>
            </div>
            <div className="header-right">
                {commonStore.currentRole === 'Admin' && <a className="link-navigate" onClick={() => navigate('/dashboard/manage-users')}>Manage Users</a>}
                {commonStore.currentRole && commonStore.currentRole !== 'Admin' && <a className="link-navigate" onClick={() => navigate('/dashboard/search')}>Search Case</a>}
                <div className="">
                    {commonStore.currentUserProfile?.fullName}
                </div>
                <div className="log-out-active" role={`button`} onClick={logout}>
                    Log out
                </div>
            </div>
        </div>
    )
}

export default HeaderBar