// import axios from 'axios'
import apiConfig from './apis/base'
import { serialize } from './utils'
import axios from 'axios'

const ssoUrl = process.env.REACT_APP_SSO_URL || ''

export const getUserProfile = async (ssoTokens) => {
    const options = {
        responseType: 'json',
        timeout: 60000,
        method: 'get',
        url: `${ssoUrl}/connect/userinfo`,
        headers: { "Authorization": `Bearer ${ssoTokens?.access_token}` }
    }

    try {
        const { data } = await axios(options)
        return data
    }
    catch (err) {
        return false
    }
}

export const createUser = async ( args, accessToken ) => {
    const options = {
        responseType: 'json',
        timeout: 60000,
        method: 'post',
        url: `${apiConfig.baseApiURL}/auth/user/create`,
        data: args,
        headers: { 
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    }

    try {
        const { data } = await axios(options)
        return data
    }
    catch (err) {
        console.log('=createUser err=', err)
        return false
    }
}

export const ssoValidateCode = async (code) => {
    const options = {
        responseType: 'json',
        timeout: 60000,
        method: 'post',
        url: `${apiConfig.baseApiURL}/auth/exchange-token`,
        data: {
            code: code
        },
        headers: { "Content-Type": "application/json" }
    }

    try {
        const { data } = await axios(options)
        if ( data?.success === false ) {
            return false
        }
        return data
    }
    catch (err) {
        console.log('=err-', err)
        return false
    }
}

export const requestOTP = async ( accessToken ) => {
    try {
        const options = {
            method: 'POST',
            url: `${apiConfig.baseApiURL}/auth/otp/send`,
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            data: {
                authToken: accessToken
            },
            // timeout: 7000
        }
    
        await axios(options)
        return true
    }
    catch(error) {
        console.error('ERROR send OTP')
        return false
    }
}

export const verifyOTP = async ( otp, accessToken ) => {
    try {
        const options = {
            method: 'POST',
            url: `${apiConfig.baseApiURL}/auth/otp/verify`,
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            data: {
                authToken: accessToken,
                otp: otp
            },
        }
    
        const { data } = await axios(options)
        return data
    }
    catch(error) {
        if ( error.response.status === 401 ) {
            window.location.href = '/'
        }
        return false
    }
}
export const logout = async () => {

    try {
        
        const authToken = JSON.parse(localStorage.getItem('nccAuthTokens'))
        const args = {
            post_logout_redirect_uri: process.env.REACT_APP_SSO_REDIRECT_URI,
            id_token_hint: authToken.access_token
        }

        localStorage.removeItem('nccAuthTokens')
        localStorage.removeItem('nccAuthTokens')

        const buffer = `${ssoUrl}/connect/endsession?${serialize(args)}`
        return window.location.href = buffer

    }
    catch(err) {
        console.error('Error parsing auth tokens')
    }
}


export const getActiveMinistry = async (userId, accessToken) => {
   
    const options = {
        responseType: 'json',
        method: 'post',
        url: `${apiConfig.baseApiURL}/general/ministry/GetActiveMinistries`,
        headers: {
            'Authorization': `Bearer ${accessToken}`
        },
        data: {
            userId: userId
        },
    }

    try {
        const { data } = await axios(options)
        return data
        // const response = await axios(options)
        // return response?.data
    }
    catch (err) {
        return err
    }
}