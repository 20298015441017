import axios from 'axios'
import moment from 'moment'
import Swal from 'sweetalert2'
const axiosInstance = axios.create();

const ssoUrl = process.env.REACT_APP_SSO_URL || ''

axiosInstance.interceptors.request.use(
    async request => {
        const lastRefreshToken = localStorage.getItem('nccLastRefreshToken')
        let duration
        if (lastRefreshToken) {
            duration = moment.duration(moment().diff(moment(lastRefreshToken)));
        }

        try {
            const authTokens = JSON.parse(localStorage.getItem('nccAuthTokens'))

            if (!lastRefreshToken || duration.asSeconds() > authTokens.expiresIn) {

                const options = {
                    responseType: 'json',
                    timeout: 60000,
                    method: 'post',
                    url: `${ssoUrl}/connect/token`,
                    data: {
                        client_secret: process.env.REACT_APP_SSO_CLIENT_SECRET,
                        grant_type: 'refresh_token',
                        refresh_token: authTokens.refresh_token,
                        client_id: encodeURI(process.env.REACT_APP_SSO_CLIENT_ID)
                    },
                    headers: { "Content-Type": "application/x-www-form-urlencoded" }
                }
                const { data } = await axios(options)

                localStorage.setItem('nccLastRefreshToken', new Date())
                localStorage.setItem('nccAuthTokens', JSON.stringify(data))

                request.headers['Authorization'] = `Bearer ${data['access_token']}`
            }
            else {
                request.headers['Authorization'] = `Bearer ${authTokens['access_token']}`
            }
        }
        catch (err) {
            console.warn('=ERROR parsing authTokens=')
            localStorage.removeItem('nccLastRefreshToken')
            localStorage.removeItem('nccAuthTokens')
            localStorage.removeItem('nccUserProfile')
            Swal.fire({
                title: '',
                icon: 'error',
                html: 'Your session has been expired',
                showCloseButton: false,
                showCancelButton: false,
                focusConfirm: true
            }).then(() => {
                window.location.href = '/'
            })
        }
        return request
    },
    error => {
        return Promise.reject(error)
    }
    , null, { synchronous: true })

// Response interceptor for API calls
axiosInstance.interceptors.response.use((response) => {
    return response
}, async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401) {
        localStorage.removeItem('nccLastRefreshToken')
        localStorage.removeItem('nccAuthTokens')
        localStorage.removeItem('nccUserProfile')
        Swal.fire({
            title: '',
            icon: 'error',
            html: 'Your session has been expired',
            showCloseButton: false,
            showCancelButton: false,
            focusConfirm: true
        }).then(() => {
            window.location.href = '/'
        })
    }
    return Promise.reject(error);
});

export const getRequestHeaders = () => {
    return {
        headers: {}
    }
}

export default {
    axiosInstance: axiosInstance,
    baseApiURL: process.env.REACT_APP_BASE_API_URL,
    casesPerPage: parseInt(process.env.REACT_APP_CASES_PER_PAGE, 10) || 10
}