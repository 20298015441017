

import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { useCommonDispatch } from '../../stores/common'

const NCSelect = (props) => {

    const dispatch = useCommonDispatch()

    const { name, isDisabled, requiredOptions, onBlur = () => { }, defaultValue, errors, control, showDescription, className, ...selectProps } = props

    const [description, setDescription] = useState('')

    useEffect(() => {
        setDesc(defaultValue)
    }, [defaultValue, selectProps?.options])

    const setDesc = (value) => {
        const opt = selectProps?.options?.find((c) => c.value === value)
        if (opt) {
            setDescription(opt?.desc || '')
        }
    }

    return (
        <>
            <Controller
                name={name}
                rules={requiredOptions}
                control={control}
                render={({ field: { onChange, value, ref } }) => {
                    return (
                        <>
                            <Select
                                {...selectProps}
                                isDisabled={isDisabled}
                                inputRef={ref}
                                value={
                                    props.isMulti === true && Array.isArray(selectProps.options) && Array.isArray(value)
                                    ? selectProps.options.filter(option => value.some(v => v.value === option.value))
                                    : selectProps.options.find(option => option.value === value)
                                }
                                className={`${className} ${errors[name] ? "formvalidate-invalid" : ""}`}
                                classNamePrefix={`${errors[name] ? "formvalidate-invalid" : ""} ncc-select-custom`}
                                onChange={(val) => {
                                    if (val) {
                                        if (props.isMulti === false) {
                                            onChange(val.value)
                                        } else {
                                            onChange(val)
                                        }
                                        setDesc(val.value)
                                    }
                                    else {
                                        onChange(null)
                                        setDesc(null)
                                    }

                                    if (props.onChange) {
                                        if (props.isMulti === false) {
                                            props.onChange(val.value)
                                        } else {
                                            props.onChange(val)
                                        }
                                    }
                                    dispatch({ type: 'FORM_CHANGED' })
                                }}
                                onBlur={onBlur}
                            />
                            {showDescription && <small>{description}</small>}
                        </>
                    );
                }}
            />
            {errors[name] && (
                <div className="feedback-invalid">
                    {errors[name].message}
                </div>
            )}
        </>
    )
}

NCSelect.defaultProps = {
    name: '',
    requiredOptions: {},
    multiple: false,
    control: () => { },
    className: '',
    showDescription: false,
    isDisabled: true
}

export default NCSelect