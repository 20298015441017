import React, { useEffect, useRef, useState } from 'react'
import MainLayout from '../../containers/MainLayout'
import {
    NCInput,
    NCSelect
} from "../../components/Fields"
import { useParams } from 'react-router-dom'
import { getAllCountryCodes } from "../../apis/countryCodes"
import { useForm, Controller } from "react-hook-form";
import Swal from "sweetalert2";
import { Spinner } from "react-bootstrap";
import { getUserById, updateLocalUser } from "../../apis/localUsers";
import { capitalize } from '../../utils'
import { useCommonState } from "../../stores/common";
import { useNavigate } from 'react-router-dom';
import { getAllMinistries } from '../../apis/ministries';
import { NCC_RS_ROLES } from '../../utils/consts';

const Loading = ({ loading }) => {
    if (loading) {
        return (
            <Spinner size="sm" variant="info" animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        )
    }
    return ''
}


const EditManagedUser = () => {

    const { id } = useParams()
    const formMethods = useForm()
    const navigate = useNavigate()

    const {
        register,
        control,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors, isDirty, touchedFields },
    } = formMethods;

    const requiredOptions = {
        required: {
            value: true,
            message: "This field is required",
        },
    };
    const allowedPerms = ['View']
    const [user, setUser] = useState()
    const [isRendering, setIsRedering] = useState(false)
    const [showRole, setShowRoles] = useState(false)
    const [roleError, setRoleError] = useState(false)
    const countryCodesRef = useRef([])
    const dataLocalUsers = useRef()
    const commonStore = useCommonState()
    const [showMinistry, setShowMinistry] = useState(false)
    const [ministryError, setMinistryError] = useState(false)
    const [ministriesList, setMinistriesList] = useState([]);


    useEffect(() => {
        if (commonStore?.currentRole !== 'Admin') {
            navigate('/no-permission/')
        }

    }, [commonStore?.currentRole])

    useEffect(() => {
        _getAllCountryCodes()
        const getUserDetails = async () => {

            const user = await getUserById(id)
            if (user.success) {
                setUser(user.data)
            }
            else {
                navigate('/no-permission/')
            }
        }
        getUserDetails()
    }, [])

    const _getUserRole = (_roles) => {
        return Array.isArray(_roles) ? _roles[0] : '';
    }

    useEffect(() => {
        if (!user) {
            return
        }
        setValue('fullName', user?.fullName)
        setValue('contactNumber', user?.contactNumber)
        setValue('contactNumberCode', user?.contactNumberCode)
        const _role = _getUserRole(user?.roles);
        setValue('roles', _role);
        setShowRoles(true);
    }, [user])

    useEffect(() => {
        if (!user || ministriesList?.length == 0) {
            return
        }
        const _role = _getUserRole(user?.roles);
        if (_role === NCC_RS_ROLES.APPROVER.id) {            
            let _ministries = [];
            if (user?.ministries) {
                for (var i = 0; i < ministriesList.length; i++) {
                    if (user?.ministries?.includes(ministriesList[i].value)) {
                        _ministries.push(ministriesList[i]);
                    }
                }
                console.log(`_ministries `, _ministries)
                setValue('approverMinistries', _ministries);
                setShowMinistry(true);
            }
        } else {
            setShowMinistry(false);
        }
    }, [user, ministriesList])


    /**
     * #48075 - NRC-178 - [Enhancement] - Manage user
     */
    useEffect(() => {
        _getMinistries()
    }, [commonStore?.ssoTokens])

    /**
     * #48075 - NRC-178 - [Enhancement] - Manage user
     */
    const _getMinistries = async () => {
        let ministriesList = [];

        const ministries = await getAllMinistries();
        if (ministries.success) {
            const filtered = filterActiveItems(ministries.data)
            ministriesList = filtered.map((i) => {
                return { value: i.id, label: i.value };
            })
        }
        setMinistriesList(ministriesList);
    };

    /**
     * #48075 - NRC-178 - [Enhancement] - Manage user
     */
    const _onRoleChanged = (value) => {
        setShowMinistry(value === NCC_RS_ROLES.APPROVER.id);
    }

    /**
     * #48075 - NRC-178 - [Enhancement] - Manage user
     */
    const onChangeMinistries = (val) => {
        setValue('approverMinistries', val)
    }

    const filterActiveItems = (items) => {
        return items.filter(i => i.isActive === true)
    }

    const _getAllCountryCodes = async () => {
        setIsRedering(true)
        const countryCodes = await getAllCountryCodes();
        if (countryCodes.success) {
            const filtered = filterActiveItems(countryCodes.data)
            countryCodesRef.current = filtered.map((i) => {
                return { value: i.id, label: `${i.value} (+${i.id})` };
            })
        } else {
            countryCodesRef.current = []
        }
        setIsRedering(false)
    }

    const handleUpdateUser = async (data) => {
        const roles = getValues('roles')
        if (!roles) {
            formMethods.setError('roles', { type: 'manual', message: 'Please select role' })
            return
        }
        let approverMinistries = [];
        if (roles === NCC_RS_ROLES.APPROVER.id) {
            const _approverMinistries = getValues('approverMinistries');
            approverMinistries = _approverMinistries.map(i => i.value);
            data['ministries'] = approverMinistries;
        }

        const status = await updateLocalUser(user?._id, data)

        if (status.success) {
            Swal.fire({
                icon: "success",
                text: "User has been updated!",
                willClose: () => {
                    navigate('/dashboard/manage-users')
                }
            })
        }
        else {
            Swal.fire({
                icon: "error",
                text: capitalize(status.message),
            })
        }
    }

    return (
        <MainLayout allowedPerms={allowedPerms}>
            <div className="content-wrapper">
                <h1 className="mt-5 mb-5 font-weight-bold">Edit User</h1>
            </div>
            <form
                onSubmit={handleSubmit(handleUpdateUser)}
                className="g-3 needs-validation"
                noValidate
            >
                <div className="case-form-wrapper">
                    <div className={`row ncc-mrb-px-30`}>
                        <div className="col-md-2">
                            <span className="span-text">Contact Number</span>
                            <span className="required-mark">*</span>
                        </div>
                        <div className="col-md-10 ncc-pr-0">
                            <div className="row">
                                <div className="col-md-3">
                                    <NCSelect
                                        options={countryCodesRef.current}
                                        name={`contactNumberCode`}
                                        isClearable={false}
                                        isSearchable={false}
                                        isMulti={false}
                                        requiredOptions={requiredOptions}
                                        control={control}
                                        errors={errors}
                                        isDisabled={true}
                                    />
                                </div>
                                <div className="col-md-9">
                                    <NCInput
                                        name={`contactNumber`}
                                        requiredOptions={requiredOptions}
                                        control={control}
                                        isDisabled={true}
                                        errors={errors}
                                        register={register}
                                        className={`ncc-pl-px-10`}
                                    />
                                    {/* {errorDupPhone && <span className="text-danger">This mobile number has an existing record.</span>} */}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="mb-3 row">
                        <div className="col-md-2">
                            <span className="span-text">Full Name</span>
                            <span className="required-mark">*</span>
                        </div>
                        <div className="col-md-10 ncc-pr-0">
                            <NCInput
                                name={`fullName`}
                                requiredOptions={requiredOptions}
                                // errors={errors}
                                control={control}
                                register={register}
                                className={`ncc-pl-px-10`}
                                isDisabled={true}
                            />
                            <NCInput
                                name={`userId`}
                                requiredOptions={{}}
                                control={control}
                                register={register}
                                className={`ncc-pl-px-10`}
                                type="hidden"
                                required={false}
                            />
                        </div>
                    </div>

                    {showRole &&
                        <div className="mb-3 row">
                            <div className="col-md-2">
                                <span className="span-text">Role</span>
                                <span className="required-mark">*</span>
                            </div>
                            <div className="col-md-10 ncc-pr-0 custom-form">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input checkbox-style" type="radio" {...register("roles")} id={NCC_RS_ROLES.REQUESTOR.id} value={NCC_RS_ROLES.REQUESTOR.id} onChange={() => _onRoleChanged(NCC_RS_ROLES.REQUESTOR.id)} />
                                    <label className="form-check-label" htmlFor="Requestor">{NCC_RS_ROLES.REQUESTOR.label}</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input checkbox-style" type="radio" {...register("roles")} id={NCC_RS_ROLES.APPROVER.id} value={NCC_RS_ROLES.APPROVER.id} onChange={() => _onRoleChanged(NCC_RS_ROLES.APPROVER.id)} />
                                    <label className="form-check-label" htmlFor="Approver">{NCC_RS_ROLES.APPROVER.label}</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input checkbox-style" type="radio" {...register("roles")} id={NCC_RS_ROLES.VIEWER.id} value={NCC_RS_ROLES.VIEWER.id} onChange={() => _onRoleChanged(NCC_RS_ROLES.VIEWER.id)} />
                                    <label className="form-check-label" htmlFor="Viewer">{NCC_RS_ROLES.VIEWER.label}</label>
                                </div>
                                {roleError && <div className="text-danger">Please select role</div>}

                            </div>
                        </div>
                    }

                    {showMinistry &&
                        <div className="row">
                            <div className="col-md-2">
                                <span className="span-text">Ministry</span>
                                <span className="required-mark">*</span>
                            </div>
                            <div className="col-md-10 ncc-pr-0 custom-form">
                                <NCSelect
                                    options={ministriesList}
                                    name={`approverMinistries`}
                                    isClearable={true}
                                    isSearchable={true}
                                    isMulti={true}
                                    requiredOptions={requiredOptions}
                                    control={control}
                                    errors={errors}
                                    isDisabled={false}
                                    onChange={onChangeMinistries}
                                />
                                {ministryError && <div className="text-danger">Please select ministries</div>}
                            </div>
                        </div>
                    }

                    <div className='d-flex justify-content-center'>
                        <button disabled={isRendering} className={`btn button-action button-save m-3`} type="submit">
                            Submit&nbsp;
                            <Loading loading={isRendering} />
                        </button>
                    </div>

                </div>
            </form>
        </MainLayout>
    )
}

export default EditManagedUser