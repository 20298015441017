/**
 * System roles
 */
export const NCC_RS_ROLES = {
  REQUESTOR: {
    id: 'Requestor',
    label: 'Requestor',
  },
  APPROVER: {
    id: 'Approver',
    label: 'Approver',
  },
  VIEWER: {
    id: 'Viewer',
    label: 'Viewer',
  }
}