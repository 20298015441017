import React, { useState, useRef, useEffect } from 'react'
import MainLayout from '../../containers/MainLayout'
import { useCommonState } from "../../stores/common";
import { getAllLocalUsers } from "../../apis/localUsers";
import { useNavigate } from "react-router-dom";
import UsersTable from '../../components/UsersTable'

const ManageUsersView = () => {

    const allowedPerms = ['View']
    const [isRendering, setIsRedering] = useState(false)
    const [users, setUsers] = useState([])
    const commonStore = useCommonState()
    let navigate = useNavigate()

    useEffect(() => {
        const _getAllLocalUsers = async () => {
            setIsRedering(true)
            let result = await getAllLocalUsers()
            if (result?.success) {
                setUsers(result.data)
            }
            setIsRedering(false)
        }
        _getAllLocalUsers()
    }, [])

    useEffect(() => {
        
        if (commonStore?.currentRole !== 'Admin' && commonStore?.currentRole !== '') {
            navigate('/no-permission/')
        }
    }, [commonStore?.currentRole])
    
    const addNewUser = (e) => {
        e.preventDefault()
        navigate('/dashboard/manage-users/add-new-user')
    }

    return (
        <MainLayout allowedPerms={allowedPerms}>
            <div className="content-wrapper">                
                <p>Manage Users who can Login to NCC Reference System here</p>
                <a onClick={addNewUser} href="#" className="mb-4 btn btn-primary ncc-mrl-px-1 test-class">Add New User</a>

                {/* Table template */}
                
                <UsersTable users={users} />
                {/* End */}
            </div>
        </MainLayout>
    )
}

export default ManageUsersView