export const categoryLevelName = (_case) => {
    try {
        const catLevel = JSON.parse(_case?.rating)
        return catLevel?.value
    }
    catch (err) {
        console.log('=ee', err)
        return ''
    }
}

export const rand = () => {
    return (Date.now() + "" + Math.random()).replace(".", "");
}

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const serialize = function (obj) {
    let str = [];
    for (let p in obj) {
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURI(obj[p]));
        }
    }
    return str.join("&");
}

export const makeId = (length = 6) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const arrayContains = (list, arr) => {

    if (!Array.isArray(arr)) {
        return false
    }

    let count = 0
    for (let i = 0; i < list.length; i++) {
        const item = list[i]
        for (let j = 0; j < arr.length; j++) {
            if (item === arr[j]) {
                count++
            }
        }
    }

    return count >= arr.length ? true : false
}

export const hasPermissions = (perms, allowedPerms) => {
    if (!Array.isArray(perms) || !Array.isArray(allowedPerms)) {
        return false
    }

    // Admin role has all permissions
    if (perms.indexOf('Admin') !== -1) {
        return true
    }

    return arrayContains(perms, allowedPerms)
}

export const capitalize = (word) => {
    return word.charAt(0).toUpperCase()
        + word.slice(1)
}