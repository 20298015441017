import React, { useState, useEffect } from "react";
import TablePagination from "../TablePagination";
import moment from "moment";
import { mapCaseStatus } from "../../utils/caseStatus";
import Spinner from "react-bootstrap/Spinner";
import { Link, useNavigate } from "react-router-dom";
import { useCommonDispatch, useCommonState } from "../../stores/common";
import { hasPermissions, categoryLevelName } from "../../utils";
import { deleteCase } from "../../apis/cases";
import Swal from "sweetalert2";
import apiConfig from '../../apis/base'
import { getAllCaseCat } from "../../apis/categoryLevels";

const CasesTable = (props) => {

    const commonStore = useCommonState()
    const dispatch = useCommonDispatch()

    const [caseCatList, setCaseCatList] = useState([]);

    const navigate = useNavigate()

    const { viewMode, cases, totalPages, totalCount, currentPage, onPageChanged, loading } = props;

    useEffect(() => {
        _getCaseCategory();
    }, [])

    const textDisplay = (_case) => {
        // if (_case.status === 'submitted' && hasPermissions(commonStore.permissions, ['Edit'])) {
        //     return `View`
        // }
        // if (_case.status === 'submitted' && hasPermissions(commonStore.permissions, ['Approve'])) {
        //     return `Pending Review`
        // }

        return 'View'
    }

    const _getCaseCategory = async () => {
        let caseCat = []
        const caseCategory = await getAllCaseCat({});
        if (caseCategory.success) {
            caseCat = caseCategory.data.map((i) => {
                return { value: i.id, label: i.value, desc: i.description };
            })
        }

        if (caseCat.length <= 0 && caseData) {
            try {
                const cat = JSON.parse(caseData?.rating)
                caseCat.push({ value: cat.id, label: cat.value })
            }
            catch (err) {
                console.warn('Error parsing ministry', err)
            }
        }
        setCaseCatList(caseCat)
    };

    const handleNavigate = (_case, action = 'view') => {
        navigate(`/dashboard/cases/${action}/${_case._id}`)
    }

    // const handleDeleteCase = async (_case) => {
    //     const result = await Swal.fire({
    //         title: 'Do you want to delete this case?',
    //         showCancelButton: true,
    //         confirmButtonText: 'Delete',
    //         reverseButtons: true
    //     })

    //     if (result.isConfirmed) {
    //         const status = await deleteCase(_case._id)
    //         if (status.success) {
    //             Swal.fire('', 'Case has been deleted successfully', 'success')
    //             dispatch({ type: 'REFRESH_CASES' })
    //         }
    //         else {
    //             Swal.fire('', 'Could not delete case', 'error')
    //         }
    //     }
    // }
    const isAllowToEdit = (caseData) => {
        if (viewMode === 'search') {
            return false;
        }
        let isAlllow = false;
        if (((caseData?.status === 'submitted' && caseData?.created_by?.userId === commonStore?.currentUserProfile?.userId) || (commonStore.currentRole === 'Admin' && (caseData?.status === 'submitted')))) {
            isAlllow = true
        }
        if (((commonStore.currentRole === 'Admin' || commonStore.currentRole === 'Approver') && caseData?.status === 'submitted')) {
            isAlllow = true
        }
        if ((commonStore.currentRole === 'Admin' && (caseData?.status === 'endorsed'))) {
            isAlllow = true
        }
        if (((commonStore.currentRole === 'Admin' || (commonStore.currentRole === 'Approver' && caseData?.status === 'submitted')) && caseData && caseData?.status !== 'rejected')) {
            isAlllow = true
        }

        if (commonStore.currentRole === 'Admin' && caseData?.status === 'discharged') {
            isAlllow = false
        }

        return isAlllow;
    }

    const isAllowToReview = (caseData) => {
        if (viewMode === 'search') {
            return false;
        }
        let isAlllow = false;
        let now = moment();
        const reviewDate = moment(caseData?.review_date);
        if ((caseData?.created_by?.userId === commonStore?.currentUserProfile?.userId) && caseData?.is_allow_review === true && caseData?.status === "approved") {
            isAlllow = true
        }
        return isAlllow;
    }

    const renderCaseCat = (caseData) => {
        return caseData?.case_category ? caseCatList?.find((i) => i.label === caseData?.case_category)?.label : 'N/A'
    }

    return (
        <div className="table-responsive">

            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th width="5%" className="text-align-center">No</th>
                        <th width="15%" className="text-align-center">Case ID</th>
                        <th width="15%" className="text-align-center">Full Name</th>
                        <th width="15%" className="text-align-center">Mobile Number</th>
                        <th width="15%" className="text-align-center">Ministry</th>
                        <th width="10%" className="text-align-center">Category</th>
                        <th width="10%" className="text-align-center">Level Of Action</th>
                        <th width="10%" className="text-align-center">Created At</th>
                        <th width="10%" className="text-align-center">Updated At</th>
                        <th width="10%" className="text-align-center">Status</th>
                        {/* <th width="10%" className="text-align-center">NonMemberId</th>
                        <th width="10%" className="text-align-center">ReferenceCheckId</th> */}
                        {viewMode !== 'search' &&
                            <th width="10%" className="text-align-center">Action</th>
                        }
                    </tr>
                </thead>
                {cases && cases.length > 0 && (

                    <tbody>
                        {cases.map((_case, i) => {
                            const ministryData = _case?.ministry
                            let ministryName;
                            if (ministryData) {
                                const ministryObject = JSON.parse(ministryData)
                                ministryName = ministryObject?.value
                            }
                            return (
                                <tr key={i} className={``}>
                                    <td className="text-align-center">{i + (apiConfig.casesPerPage * (currentPage - 1)) + 1}</td>
                                    <td className="text-align-center">
                                        {viewMode !== 'search' &&
                                            <a onClick={() => handleNavigate(_case)} className={`cursor-pointer item item-${_case.status}`}>
                                                {_case.name}
                                            </a>
                                        }
                                        {viewMode === 'search' &&
                                            _case.name
                                        }
                                    </td>
                                    <td className="text-align-center">{_case.full_name}</td>
                                    <td className="text-align-center">
                                        {_case.contact_number}
                                    </td>
                                    <td className="text-align-center">
                                        {/* {_case.contact_number} */}
                                        {ministryName}
                                    </td>
                                    <td className="text-align-center">
                                        {renderCaseCat(_case)}
                                    </td>
                                    <td className="text-align-center">
                                        {categoryLevelName(_case)}
                                    </td>
                                    <td className="text-align-center">
                                        {moment(_case.createdAt).format("lll")}
                                    </td>
                                    <td className="text-align-center">
                                        {moment(_case.updatedAt).format("lll")}
                                    </td>
                                    <td className="text-align-center">
                                        {mapCaseStatus(_case?.status)}
                                    </td>
                                    {/* <td className="text-align-center">
                                        {_case?.NonMemberId}
                                    </td>
                                    <td className="text-align-center">
                                        {_case?.ReferenceCheckId}
                                    </td> */}
                                    {viewMode !== 'search' &&
                                        <td className="text-align-center">
                                            <a onClick={() => handleNavigate(_case)} className={`cursor-pointer item item-${_case.status}`}>
                                                {/* {textDisplay(_case)} */}
                                                View
                                            </a>
                                            &nbsp;
                                            {
                                                isAllowToEdit(_case) === true ? <a onClick={() => handleNavigate(_case, "edit")} className={`cursor-pointer text-color-red item item-${_case.status}`}>
                                                    Edit
                                                </a> : ''
                                            }
                                            <br />
                                            {
                                                isAllowToReview(_case) === true ? <a onClick={() => handleNavigate(_case, "review")} className={`cursor-pointer text-color-red item item-${_case.status}`}>
                                                    Review
                                                </a> : ''
                                            }

                                            {/* {hasPermissions(commonStore.permissions, ['Admin']) &&
                                        <a onClick={() => handleDeleteCase(_case)} className={`d-block text-red cursor-pointer item item-${_case.status}`}>
                                            Delete
                                        </a>} */}
                                        </td>
                                    }
                                </tr>
                            )
                        })}
                    </tbody>
                )}

                {loading && (
                    <tbody>
                        <tr>
                            <td colSpan={11}>
                                <div className="text-center">
                                    <Spinner size="sm" variant="info" animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </div>
                            </td>
                        </tr>
                    </tbody>)}

                {!loading && cases.length === 0 && (
                    <tbody>
                        <tr>
                            <td colSpan={11}>
                                <div className="no-results-wrapper">
                                    <p className="mt-1 mb-1 text-center no-result">No results for this search</p>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                )}
            </table>

            {cases && cases.length > 0 &&
                <TablePagination
                    loading={loading}
                    totalPages={totalPages}
                    onPageChange={onPageChanged}
                    totalCount={totalCount}
                    currentPage={currentPage}
                />}
        </div>
    );
};

export default CasesTable;
