import { useEffect, useState } from "react"
import { Controller } from "react-hook-form";
import { useCommonDispatch } from '../../stores/common'

const NCInput = ( props ) => {
    
    const dispatch = useCommonDispatch()

    const { 
        type, name, errors, className,
        isDisabled,
        control,
        required,
        maxLength, 
        minLength,
        onBlur = () => {},
    } = props
    
    const [requiredOptions, setRequiredOptions] = useState({})
    useEffect(() => {
        if ( required === true ) {
            requiredOptions.required = {
                value: true,
                message: "This field is required",
            }
        }

        if ( maxLength ) {
            requiredOptions.maxLength = {
                value: maxLength,
                message: `Max length exceeded (${maxLength})`
            }
        }

        if ( minLength ) {
            requiredOptions.minLength = {
                value: minLength,
                message: `Min length exceeded (${minLength})`
            }
        }

        setRequiredOptions( requiredOptions )
    }, [])
    
    return (
        <>
            <Controller
                name={name}
                rules={requiredOptions}
                control={control}
                render={({ field: { onChange, value } }) => {
                    return (
                        <input
                            type={type}
                            value={value || ''}
                            disabled={isDisabled}
                            className={`main-input-style ${className} ${errors[name] ? "formvalidate-invalid" : ""}`}
                            onChange={(val) => {
                                onChange(val.target.value)
                                if ( props.onChange ) {
                                    props.onChange(val.target.value)
                                }
                                dispatch({ type: 'FORM_CHANGED' })
                            }}
                            onBlur={(val) => { onBlur(val.target.value)}}
                        />
                    );
                }}
            />
            {errors[name] && (
                <div className="feedback-invalid">
                    {errors[name].message}
                </div>
            )}
        </>
    )
}

NCInput.defaultProps = {
    type: 'text',
    required: true,
    maxLength: 200,
    errors: {},
    register: () => {},
    requiredOptions: {},
    className: '',
    isDisabled: false,
    onChange: () => {}
}

export default NCInput