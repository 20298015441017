import { useCallback, useEffect, useMemo, useState } from "react";
import CaseSearch from '../../components/CaseSearch'
import CasesTable from '../../components/CasesTable'
import { 
    getCases 
} from '../../apis/cases'
import MainLayout from '../../containers/MainLayout'
import { hasPermissions } from "../../utils";
import { useCommonState } from "../../stores/common";
import { useNavigate } from 'react-router-dom';

const CasesList = () => {
    
    const allowedPerms = [ 'View' ]

    const commonStore = useCommonState()

    const [casesList, setCasesList] = useState([])
    const [totalPages, setTotalPages] = useState(1)
    const [totalCount, setTotalCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [keyword, setKeyword] = useState('')
    const [loading, setLoading] = useState(false)
    const [perms, setPerms] = useState(false)
    const [ministryFilter, setMinistryFilter] = useState(null)
    const [startDateFilter, setStartDateFilter] = useState(null)
    const [endDateFilter, setEndDateFilter] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
        setPerms( commonStore.permissions )
    }, [commonStore.permissions])

    useEffect(() => {
        
        if (commonStore?.currentRole === 'Viewer') {
            navigate('/dashboard/search')
        }

    }, [commonStore?.currentRole])

    useEffect(() => {
        if ( hasPermissions(perms, ['View'])) {
            _getCasesList()
        }
    }, [commonStore.refreshCases])

    useEffect(() =>{
        _getCasesList()
    }, [ministryFilter, startDateFilter, endDateFilter, commonStore.currentRole])

    useEffect(() => {
        setCasesList([])
        if ( hasPermissions(perms, ['View'])) {
            _getCasesList()
        }
    }, [ keyword, currentPage, perms ])

    const _getCasesList = async () => {
        
        if ( loading || !commonStore?.currentRole ) {
            return
        }
        
        setLoading( true )
        console.log({
            page: currentPage,
            keyword: keyword,
            role: commonStore.currentRole,
            ministryFilter: ministryFilter,
            startDateFilter: startDateFilter,
            endDateFilter: endDateFilter
        })
        const result = await getCases({
            page: currentPage,
            keyword: keyword,
            role: commonStore.currentRole,
            ministryFilter: ministryFilter,
            startDateFilter: startDateFilter,
            endDateFilter: endDateFilter
        })
        setLoading( false )
        
        if ( result.success ) {
            const { data, totalPage, totalCount } = result.data
            setCasesList( data )
            setTotalPages( totalPage )
            setTotalCount( totalCount )
        }
    }
    
    return (
        <MainLayout allowedPerms={allowedPerms}>
            
            <div className="content-wrapper">
                <h1 className="mt-5 mb-5 font-weight-bold">Cases List</h1>
            
                <CaseSearch 
                    onKeywordChange={(value) => setKeyword(value)}
                    onMinistryFilterChange={(data) => setMinistryFilter(data)}
                    onStartDateFilterChange={(data) => setStartDateFilter(data)}
                    onEndDateFilterChange={(data) => setEndDateFilter(data)}
                />

                <CasesTable 
                    onPageChanged={(nextPage) => setCurrentPage( nextPage )}
                    cases={casesList}
                    totalPages={totalPages}
                    totalCount={totalCount}
                    currentPage={currentPage}
                    loading={loading}
                />
            </div>
            
        </MainLayout>
    )
}

export default CasesList