import { isArray } from 'lodash'
import apiConfig, {
    getRequestHeaders
} from './base'

const { axiosInstance } = apiConfig

const baseApiURL = apiConfig.baseApiURL

const parseErrorMessage = (error) => {
    let message = ''
    try {
        if (typeof error.response != 'undefined'
            && typeof error.response.data != 'undefined'
            && typeof error.response.data.message != 'undefined') {
            message = error.response.data.message
        }
        else if (typeof error.response != 'undefined'
            && typeof error.response.data != 'undefined'
            && typeof error.response.data.error != 'undefined') {
            message = error.response.data.error
        }
        else {
            message = error.message
        }
    }
    catch (e) {
        message = 'Something wrong. Please try again!'
    }
    return isArray(message) ? message[0] : message
}

/** 
 * Get local users
 **/
export const postFindLocalUsers = async () => {

    let result = {
        success: false
    }

    let args = {
        mobile: 908181283,
        // mobile: 9081812831,
        countryCode: `+${84}`
    }

    const requestHeaders = getRequestHeaders()

    try {
        const { data } = await axiosInstance.post(`${baseApiURL}/local-users/find-user/`, args, requestHeaders)
        result = data
    }
    catch (e) {
        result.message = e.message
    }

    return result
}

/** 
 * Get local users
 **/
export const getAllLocalUsers = async () => {

    let result = {
        success: false
    }

    const requestHeaders = getRequestHeaders()

    try {
        const { data } = await axiosInstance.get(`${baseApiURL}/local-users`, requestHeaders)
        result = data
    }
    catch (e) {
        result.message = e.message
    }

    return result
}

/**
 * #48072 - NRC-175 - [Enhancement] - Submit case
 * @param {*} id 
 * @returns 
 */
export const getAllLocalUsersByMinistryId = async (id) => {

    let result = {
        success: false
    };
    const requestHeaders = getRequestHeaders()
    try {
        const { data } = await axiosInstance.get(`${baseApiURL}/local-users/find-approvers-by-ministry-id/${id}`, requestHeaders)
        result = data;
    }
    catch (e) {
        result.message = e.message;
    }
    return result;
}

export const getUserById = async (id) => {

    let result = {
        success: false
    }

    const requestHeaders = getRequestHeaders()

    try {
        const { data } = await axiosInstance.get(`${baseApiURL}/local-users/${id}`, requestHeaders)
        result = data
    }
    catch (e) {
        result.message = e.message
    }

    return result
}

export const getUserByUserId = async (userId) => {

    let result = {
        success: false
    }

    const requestHeaders = getRequestHeaders()

    try {
        const { data } = await axiosInstance.get(`${baseApiURL}/local-users/get-user/${userId}`, requestHeaders)
        result = data
    }
    catch (e) {
        result.message = e.message
    }

    return result
}

/** 
 * Get local users
 **/
export const addLocalUsers = async (args) => {

    let result = {
        success: false
    }

    const requestHeaders = getRequestHeaders()

    try {
        const { data } = await axiosInstance.post(`${baseApiURL}/local-users/add-local-user/`, args, requestHeaders)
        result = data
    }
    catch (e) {
        result.message = parseErrorMessage(e)
    }

    return result
}

/**
 * Update local user
 * @param {object} args 
 * @returns 
 */
export const updateLocalUser = async (id, args) => {

    let result = {
        success: false
    }

    const requestHeaders = getRequestHeaders()
    try {
        const { data } = await axiosInstance.patch(`${baseApiURL}/local-users/${id}`, args, requestHeaders)
        result = data
    }
    catch (e) {
        result.message = parseErrorMessage(e)
    }

    return result
}



/** 
 * Get local users
 **/
export const removeLocaUser = async (args) => {

    let result = {
        success: false
    }

    const requestHeaders = getRequestHeaders()

    try {
        const { data } = await axiosInstance.post(`${baseApiURL}/local-users/remove-local-user/`, args, requestHeaders)
        result = data
    }
    catch (e) {
        result.message = e.message
    }

    return result
}

export const searchCaseLocalUsers = async (args) => {
    let result = {
        success: false
    }

    const requestHeaders = getRequestHeaders()

    try {
        const { data } = await axiosInstance.post(`${baseApiURL}/cases/search-case/`, args, requestHeaders)
        result = data
    }
    catch (e) {
        result.message = e.message
    }

    return result
}

/**
 * #48074 - NRC-177 - [Enhancement] - Search screen
 * @param {*} args 
 * @returns 
 */
export const fetchCasesByCaseIDs = async (args) => {
    let result = {
        success: false
    }
    const requestHeaders = getRequestHeaders()
    try {
        const { data } = await axiosInstance.post(`${baseApiURL}/cases/fetch-case-details/`, args, requestHeaders)
        result = data;
    }
    catch (e) {
        result.message = e.message;
    }

    return result
}