// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { HashRouter as Router, Route, Routes } from 'react-router-dom'

import Home from "../pages/Home"
import Dashboard from "../pages/Dashboard"
import CasesList from '../pages/Cases'
import EditCase from '../pages/Cases/EditCase'
import ViewCase from '../pages/Cases/ViewCase'
import AddCase from '../pages/Cases/AddCase'
import NoPermission from '../pages/NoPermission'
import OTPInput from '../pages/OTPInput'
import ReviewCase from '../pages/Cases/ReviewCase'
import ManageUsersView  from '../pages/ManageUsers'
import AddNewLocalUsers from '../pages/ManageUsers/AddNewLocalUsers'
import EditManagedUser from '../pages/ManageUsers/EditManagedUser'
import SearchCase from '../pages/SearchCase'

const router = (
    <Router>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="no-permission" element={<NoPermission />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="dashboard/cases" element={<CasesList />} />
            <Route path="dashboard/cases/new" element={<AddCase />} />
            <Route path="dashboard/cases/edit/:id" element={<EditCase />} />
            <Route path="dashboard/cases/view/:id" element={<ViewCase />} />
            <Route path="dashboard/cases/review/:id" element={<ReviewCase />} />
            <Route path="dashboard/verify-otp" element={<OTPInput />} />
            <Route path="dashboard/manage-users" element={<ManageUsersView />} />
            <Route path="dashboard/manage-users/add-new-user" element={<AddNewLocalUsers />} />
            <Route path="dashboard/manage-users/edit/:id" element={<EditManagedUser />} />
            <Route path="dashboard/search" element={<SearchCase />} />
        </Routes>
    </Router>
)

export default router