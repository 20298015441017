import { useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";
import { makeId } from "../../utils";

const NCFileInput = (props) => {

    const {
        name, errors, label, requiredOptions, register, getValues, setValue,
        containerClassName,
        wrapperClassName,
        files,
        isDisabled,
        type
    } = props

    const fieldId = useMemo(() => `field-${makeId()}`, [])
    const [file, setFile] = useState()
    const handleChangeFile = (evt) => {
        const fileValue = getValues(name)
        const validatedFiles = validateFiles(fileValue)
        if ( validatedFiles.length > 0 ) {
            setFile(validatedFiles[0])
        }
        else {
            let errorMessage = 'Accept image only'
            if ( type === 'document' ) {
                errorMessage = 'Only doc, docx, zip, txt and pdf are allowed'
            }
            Swal.fire('', errorMessage, 'error')
            setValue(name, '')
        }
    }

    const validateFiles = (files) => {
        let validatedFiles = []
        let allowedFiles = ['png', 'gif', 'jpg', 'jpeg']
        if ( type === 'document' ) {
            allowedFiles = [ 'doc', 'docx', 'zip', 'pdf', 'txt' ]
        }
        for (let i = 0; i < files.length; i++) {
            const ext = files[i]?.name.split('.').pop()
            if ( allowedFiles.indexOf(ext) !== -1 ) {
                validatedFiles.push( files[i])
            }
        }

        return validatedFiles
    }


    return (
        <label className={`ncc-custom-file-input ${containerClassName}`}>
            <div className={wrapperClassName}>
                {/* <span>Change: </span> */}
                {isDisabled === false &&
                    <>
                        <label htmlFor={fieldId} className="btn btn-success">
                            {label}
                        </label>
                        <input
                            type="file"
                            id={fieldId}
                            {...register(name, {
                                ...requiredOptions,
                                ...{
                                    onChange: (evt) => {
                                        handleChangeFile(evt);
                                    },
                                },
                            })}
                        />
                    </>}
                {file && <div className="ncc-pl-3 file-attached"><span className="file-attached">{file.name}</span></div>}
                {files && files?.length > 0 && !file &&
                    <div className="ncc-pl-3 file-attached">
                        {/* <span className="file-attached">{files[0]?.filename}</span> */}
                        <span>
                            {type === 'document' ?
                                <a href={`${process.env.REACT_APP_BASE_API_URL}/medias/${files[0].filename}`} className="">Download</a>
                                :
                                <img src={`data:${files[0]?.mimetype};base64, ${files[0]?.base64}`} style={{ maxWidth: '80px' }} />}
                        </span>
                    </div>}
            </div>
            {errors[name] && (
                <div className="feedback-invalid">
                    {errors[name].message}
                </div>
            )}
        </label>
    )
}

NCFileInput.defaultProps = {
    name: '',
    label: 'Upload',
    containerClassName: '',
    wrapperClassName: '',
    isDisabled: false,
    errors: {},
    requiredOptions: {},
    getValues: () => { },
    register: () => { },
    type: 'photos'
}

export default NCFileInput