import { Controller } from "react-hook-form";
import ReactDatePicker from "react-datepicker";

// Datepicker component
const NCDatePicker = ( props ) => {

    const { name, register, requiredOptions, errors, control, disabled, ...datePickerProps } = props

    return ( 
        <>
            <Controller
                control={control}
                name={name}
                render={({ field: { onChange, value, ref } }) => {
                    return (
                        <ReactDatePicker
                            ref={ref}
                            {...datePickerProps}
                            selected={value}
                            {...register(name, requiredOptions)}
                            onChange={onChange}
                            disabled={disabled}
                        />
                    );
                }}
            />
            {errors[name] && (
                <div className="feedback-invalid">
                    {errors[name].message}
                </div>
            )}
        </>
    )
}

NCDatePicker.defaultProps = {
    name: '',
    errors: {},
    register: () => {},
    control: () => {},
    requiredOptions: {},
    disabled: false
}

export default NCDatePicker