import React, { useEffect, useRef, useState } from 'react'
import MainLayout from '../../containers/MainLayout'
import {
    NCInput,
    NCSelect,
    NCDatePicker,
    NCFileInput
} from "../../components/Fields"
import { getAllCountryCodes } from "../../apis/countryCodes"
import { useCommonState } from "../../stores/common";
import { useForm, Controller } from "react-hook-form";
import { mobileLookup } from "../../apis/contacts";
import Swal from "sweetalert2";
import { Spinner } from "react-bootstrap";
import { addLocalUsers, fetchCasesByCaseIDs, searchCaseLocalUsers } from "../../apis/localUsers";
import { useNavigate } from 'react-router-dom';
import CasesTable from '../../components/CasesTable';

const Loading = ({ loading }) => {

    if (loading) {
        return (
            <Spinner size="sm" variant="info" animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        )
    }
    return ''
}


const SearchCase = () => {

    const navigate = useNavigate()
    const commonStore = useCommonState()

    const formMethods = useForm()

    const {
        register,
        control,
        watch,
        setError,
        setValue,
        getValues,
        clearErrors,
        handleSubmit,
        formState: { errors, isDirty, touchedFields },
    } = formMethods;

    const { contactNumber, contactNumberCode, fullName } = watch()

    // const requiredOptions = {
    //     required: {
    //         value: true,
    //         message: "This field is required",
    //     },
    // };
    const allowedPerms = ['View']
    const [isRendering, setIsRedering] = useState(false)
    const [disabledSubmit, setDisabledSubmit] = useState(true)
    const countryCodesRef = useRef([])
    const dataLocalUsers = useRef()
    const [casesListFound, setCasesListFound] = useState([]);

    useEffect(() => {
        _getAllCountryCodes()
    }, [])

    useEffect(() => {
        // console.log('==commonStore.currentRole==', commonStore.currentRole)
        if (commonStore.currentRole === 'Admin') {
            navigate('/no-permission/')
        }
    }, [commonStore.currentRole])

    const isNotEmpty = (val) => {
        return (val && typeof val !== 'undefined') ? true : false
    }

    useEffect(() => {
        if (!isNotEmpty(contactNumberCode) && !isNotEmpty(contactNumber) && !isNotEmpty(fullName)) {
            setDisabledSubmit(true)
        }
        else {
            setDisabledSubmit(false)
        }

        if (isNotEmpty(contactNumber) && !isNotEmpty(contactNumberCode)) {
            // console.log('1')
            setError('contactNumberCode', { type: 'custom', message: 'This field is required' });
        }
        else if (!isNotEmpty(contactNumber) && isNotEmpty(contactNumberCode)) {
            // console.log('2')
            setError('contactNumber', { type: 'custom', message: 'This field is required' });
        }
        else {
            clearErrors(['contactNumber', 'contactNumberCode'])
        }

    }, [contactNumber, contactNumberCode, fullName])

    const filterActiveItems = (items) => {
        return items.filter(i => i.isActive === true)
    }

    const _getAllCountryCodes = async () => {
        setIsRedering(true)
        const countryCodes = await getAllCountryCodes();
        if (countryCodes.success) {
            const filtered = filterActiveItems(countryCodes.data)
            countryCodesRef.current = filtered.map((i) => {
                return { value: i.id, label: `${i.value} (+${i.id})` };
            })
        } else {
            countryCodesRef.current = []
        }
        setIsRedering(false)
    }

    /**
     * #48074 - NRC-177 - [Enhancement] - Search screen
     * @param {*} found_case_id 
     */
    const _postHandleSearchCaseFoundFetchCaseDetails = async (found_case_id) => {
        let searchResult = await fetchCasesByCaseIDs({
            case_ids: found_case_id
        });

        if (searchResult?.success && searchResult?.data) {
            setCasesListFound(searchResult?.data);
        } else {
            setCasesListFound([]);
        }
    }

    const handleSearchCase = async (data) => {
        setCasesListFound([]);
        const contactNumberCountryCode = getValues('contactNumberCode')
        const contactNumber = getValues('contactNumber')
        const fullName = getValues('fullName')

        let args = {
            contact_number_country_code: contactNumberCountryCode || '',
            contact_number: contactNumber || '',
            full_name: fullName || '',
        }

        let searchResult = await searchCaseLocalUsers(args)

        if (searchResult?.success) {
            let message = {};
            if (searchResult?.found_case) {
                if (fullName && contactNumber) {
                    message = { title: '', type: 'warning', message: `<p>A record is found for ${fullName} with contact number ${contactNumberCountryCode}${contactNumber}</p>` }
                }
                else if (fullName) {
                    message = { title: '', type: 'warning', message: `<p>A record is found for ${fullName}</p>` }
                }
                else {
                    message = { title: '', type: 'warning', message: `<p>A record is found for contact number ${contactNumberCountryCode}${contactNumber}</p>` }
                }
            } else {
                message = { title: '', type: 'warning', message: '<p>Record not found in system</p>' }
            }
            const _result = await Swal.fire({
                icon: message.type,
                title: message.title,
                html: message.message,
                confirmButtonText: 'OK'
            }).then((value) => {
                return value
            });
            if (_result?.isConfirmed && searchResult?.found_case) {
                _postHandleSearchCaseFoundFetchCaseDetails(searchResult?.found_case_id);
            }
        }
    }

    return (
        <MainLayout allowedPerms={allowedPerms}>
            <div className="content-wrapper">
                <h1 className="mt-3 mb-3 font-weight-bold">Search</h1>
                <p>Please note that search will be performed by case sensitive, full value on phone number
                    AND/OR full name search only.
                </p>
            </div>
            <form
                onSubmit={handleSubmit(handleSearchCase)}
                className="g-3 needs-validation"
                noValidate
            >
                <div className="case-form-wrapper">
                    <div className={`row ncc-mrb-px-30`}>
                        <div className="col-md-2">
                            <span className="span-text">Contact Number</span>
                            {/* <span className="required-mark">*</span> */}
                        </div>
                        <div className="col-md-10 ncc-pr-0">
                            <div className="row">
                                <div className="col-md-3">
                                    <NCSelect
                                        options={countryCodesRef.current}
                                        name={`contactNumberCode`}
                                        isClearable={true}
                                        isSearchable={true}
                                        isMulti={false}
                                        control={control}
                                        register={register}
                                        errors={errors}
                                        isDisabled={false}
                                    />
                                </div>
                                <div className="col-md-9">
                                    <NCInput
                                        name={`contactNumber`}
                                        control={control}
                                        errors={errors}
                                        required={false}
                                        register={register}
                                        className={`ncc-pl-px-10`}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="mb-3 row">
                        <div className="col-md-2">
                            <span className="span-text">Full Name</span>
                            {/* <span className="required-mark">*</span> */}
                        </div>
                        <div className="col-md-10 ncc-pr-0">
                            <NCInput
                                name={`fullName`}
                                // requiredOptions={requiredOptions}
                                // errors={errors}
                                control={control}
                                register={register}
                                className={`ncc-pl-px-10`}
                                isDisabled={false}
                                required={false}
                            />
                        </div>
                    </div>

                    <div className='d-flex justify-content-center'>
                        <button disabled={isRendering || disabledSubmit} className={`btn button-action button-save m-3`} type="submit">
                            Submit&nbsp;
                            <Loading loading={isRendering} />
                        </button>
                    </div>

                    <div>
                        {casesListFound?.length > 0 &&
                            <CasesTable
                                viewMode={'search'}
                                cases={casesListFound}
                                currentPage={1}
                                loading={false}
                            />
                        }
                    </div>

                </div>
            </form>
        </MainLayout>
    )
}

export default SearchCase