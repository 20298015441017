import {React, useEffect, useState} from "react";
import { Link, useNavigate } from 'react-router-dom'
import { useCommonState } from "../../stores/common";
import { hasPermissions } from "../../utils";
import { getAllMinistries } from "../../apis/ministries";

import DatePicker from "react-datepicker";
import Select from 'react-select'
import CalendarSvg from "../CaseForm/CalendarSvg";

import moment from "moment";


const CaseSearch = (props) => {

    const commonStore = useCommonState()

    const navigate = useNavigate()

    const { onKeywordChange, onMinistryFilterChange, onStartDateFilterChange, onEndDateFilterChange} = props
    const [ keyword, setKeyword ] = useState('')
    const [ministryOptions, setMinistryOptions] = useState([
        {value: 'ALL', label: 'All Ministry'}
    ])
    const [startDateFilter, setStartDateFilter] = useState(null)
    const [endDateFilter, setEndDateFilter] = useState(null)

    const handleChangeStartDate = (data) =>{
        setStartDateFilter(data)
        let startDateFilter = null;
        if(data && data !== null){
            startDateFilter = moment(data).format('YYYY-MM-DD')
        }
        onStartDateFilterChange(startDateFilter)
    }

    const handleChangeEndDate = (data) =>{
        setEndDateFilter(data)
        let endDateFilter = null;
        if(data && data !== null){
            endDateFilter = moment(data).format('YYYY-MM-DD')
        }
        onEndDateFilterChange(endDateFilter)
    }


    useEffect(()=>{
        const fetchMinitriesFilterByRole = async () =>{
            const minitriesFilterResponse = await getAllMinistries();
            if(minitriesFilterResponse?.success){
                const ministryData = minitriesFilterResponse?.data.map((i)=>{
                    return { value: i.id, label: i.value };
                })
                setMinistryOptions(ministryOptions.concat(ministryData))
            }
        }

        fetchMinitriesFilterByRole()
    }, [])

    const triggerSearch = () => {
        onKeywordChange( keyword )
    }

    const addNewPage = (evt) => {
        evt.preventDefault()
        navigate('/dashboard/cases/new')
    }

    const onChangeMinistryFilter = (data) =>{
        onMinistryFilterChange(data)
    }

    const handleFocusStartDatePicker = () => {
		document.getElementsByName('start_date_picker')[0].focus()
	}

    const handleFocusEndDatePicker = () =>{
        document.getElementsByName('end_date_picker')[0].focus()
    }

     
    return (
        <>
            <p>Begin by checking for any existing records for this case.</p>
            <div className="row ncc-mrb-px-15 flex-wrap ncc-case-search-wrapper">
                <div className="col-lg-4 search-input">
                    <div className="case-search-wrapper">
                        <div className="ncc-pos-relative col-md-12">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search search-svg" viewBox="0 0 16 16">
                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                            </svg>
                            <input className={`main-input-style ncc-pl-px-30 ncc-pr-px-10`} type="search" placeholder="Search by Case ID, Full Name, Mobile Number" onChange={(e) => setKeyword(e.target.value)}/>
                        </div>
                    </div>
                </div>

                <div className="col-md-2 other-search-input first-other other-date-picker">
                    <div className="row full-height margin-left-0 margin-right-0">
                        <div className="col-md-4 d-flex justify-content-center cursor-pointer unset-padding full-height other-date-picker-inner" onClick={() => handleFocusStartDatePicker()}>
                            <CalendarSvg 
                                className="full-height"
                            />
                        </div>
                        <div className="col-md-8  unset-padding full-height other-date-picker-datepicker">
                            <DatePicker
                                placeholderText="Start Date"
                                name='start_date_picker'
                                className="full-height nc-date-picker"
                                wrapperClassName="full-height"
                                onChange={handleChangeStartDate}
                                selected={startDateFilter}
                                isClearable={true}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-2 other-search-input other-date-picker">
                    <div className="row full-height margin-left-0 margin-right-0">
                        <div className="col-md-4 d-flex justify-content-center cursor-pointer unset-padding full-height other-date-picker-inner" onClick={() => handleFocusEndDatePicker()}>
                            <CalendarSvg
                                className="full-height"
                            />
                        </div>
                        <div className="col-md-8 unset-padding full-height other-date-picker-datepicker">
                            <DatePicker
                                placeholderText="End Date"
                                name='end_date_picker'
                                className="full-height nc-date-picker"
                                wrapperClassName="full-height"
                                onChange={handleChangeEndDate}
                                selected={endDateFilter}
                                isClearable={true}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-2 other-search-input picker-ministry">
                    <Select
                        options={ministryOptions}
                        onChange={onChangeMinistryFilter}
                        defaultValue={ministryOptions[0]}
                        className="ministries-filter"
                    />
                </div>
                
                <div className="col-md-2 other-search-input last-other">
                    <a className="btn btn-success" onClick={triggerSearch}>Search</a>
                    
                    {/* <a className="btn btn-primary ncc-mrl-px-15" href="/dashboard/cases/new">Submit case</a> */}
                    {hasPermissions( commonStore.permissions, [ 'Submit' ]) && 
                        <a onClick={addNewPage} href="#" className="btn btn-primary ncc-mrl-px-1 test-class">Submit case</a>
                    }
                </div>
            </div>
        </>
    )

}

export default CaseSearch