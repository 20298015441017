import React, { useState, useEffect } from 'react';
import BaseModal from '../Modals/BaseModal';


const ModalApprove = (props) => {

    const { show, toggle = () => {} } = props
    

    useEffect(() => {

    }, [])

    return (
        <BaseModal
            show={show}
            title={`Approve Case`}
            size={'md'}
            centered
            // backdrop="static"
            className="ncc-otp-input ncc-approve-reject-modal"
        >
            <div className="text-center">This case has been approved successfully</div>
            <div className="dfl-r-jctr-alctr ncc-w-100 mt-5">
                <button className="btn btn-info text-white" onClick={toggle}>Close</button>
            </div>
        </BaseModal>
    )
}

export default ModalApprove