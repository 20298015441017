// import React, { useContext, createContext, useReducer } from 'react'
import React, { useContext, createContext, useReducer } from 'react'

const CommonStateContext = createContext("common");
const CommonDispatchContext = createContext("common");

const initialState = {
    isInitializing: true,
    currentUserProfile: {},
    currentCaseToEdit: null,
    casesCurrentPage: 1,
    casesTotalPages: 0,
    formChanged: 0,
    permissions: [],
    refreshCases: 0,
    currentRole: '',
    ssoTokens: {}
}

const Reducer = (state, action) => {
    switch (action.type) {
        case "NCC_INITIALIZING": return {
            ...state,
            isInitializing: action.payload
        }
        case "NCC_USER_PROFILE": return {
            ...state,
            currentUserProfile: action.payload
        }
        case "CURRENT_CASE_TO_EDIT": return {
            ...state,
            currentCaseToEdit: action.payload
        }
        case "CASES_CURRENT_PAGE": return {
            ...state,
            casesCurrentPage: action.payload
        }
        case "FORM_CHANGED": return {
            ...state,
            formChanged: state.formChanged + 1
        }
        case "PERMISSIONS": return {
            ...state,
            permissions: action.payload
        }
        case "CURRENT_ROLE": return {
            ...state,
            currentRole: action.payload
        }
        case "REFRESH_CASES": return {
            ...state,
            refreshCases: state.refreshCases + 1
        }
        case "SSO_TOKENS": return {
            ...state,
            ssoTokens: action.payload
        }
        default: return state
    }
}

const CommonStore = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, initialState);
    return (
        <CommonStateContext.Provider value={state}>
            <CommonDispatchContext.Provider value={dispatch}>
                {children}
            </CommonDispatchContext.Provider>
        </CommonStateContext.Provider>
    )
}
export default CommonStore

export function useCommonDispatch() {
    const context = useContext(CommonDispatchContext);
    return context;
}

export function useCommonState() {
    const context = useContext(CommonStateContext);
    return context;
}
