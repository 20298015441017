import CaseForm from '../../components/CaseForm';
import MainLayout from '../../containers/MainLayout'
import { useEffect, useState } from 'react';
import { editCase, getCaseDetails } from '../../apis/cases'
import { useNavigate, useParams } from 'react-router-dom';
import { mapCaseStatus } from '../../utils/caseStatus';
import { capitalizeFirstLetter } from '../../utils';
import moment from 'moment';
// import NoPermission from "../NoPermission"
import { useCommonDispatch, useCommonState } from "../../stores/common";

const ReviewCase = (props) => {

    const [caseData, setCaseData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isAllowed, setIsAllowed] = useState(false)
    const { id } = useParams()
    const navigate = useNavigate()
    const commonStore = useCommonState()
    const dispatch = useCommonDispatch()

    useEffect(() => {
        _getCaseDetails()
    }, [commonStore?.currentRole])

    useEffect(() => {
        if (caseData?.created_by?.userId === commonStore?.currentUserProfile?.userId) {
            setIsAllowed(true)
        } else {
            setIsAllowed(false)
        }
    }, [caseData, commonStore.currentUserProfile])

    const _getCaseDetails = async () => {
        setIsLoading(true)
        const _case = await getCaseDetails({ id: id, current_role: commonStore?.currentRole })
        if (_case.success) {
            setCaseData(_case.data)
            setIsLoading(false)
        }
        else {
            setIsLoading(false)
            navigate('/no-permission/')
        }
    }

    const onSubmit = async (params) => {
        // console.log(`onSubmit review case`);
    }

    return (
        <>
            <MainLayout>
                {isAllowed && caseData &&
                    <div className="ncc-pos-relative">
                        <div className="container pb-5">
                            <h1 className="mb-5">Case Details</h1>
                            <div className="row">
                                <div className="col-md-9">
                                    <CaseForm caseData={caseData} loading={isLoading} onSubmit={onSubmit} isInReview={true} viewAction='review' />
                                </div>
                                <div className="col-md-3">
                                    <div className="case-notes-wrapper border p-2">
                                        <div className="d-flex">
                                            <h5>Status: &nbsp;</h5>
                                            <h5 className="text-center ml-3">{mapCaseStatus(caseData?.status)}</h5>
                                        </div>
                                        <h5>Case Notes</h5>
                                        <ul className="list-group">
                                            {caseData && caseData?.trackings.length > 0 && caseData?.trackings.map((tracking, i) => {
                                                return (
                                                    <li key={i} className="list-group-item">
                                                        {moment(tracking.createdAt).format('LLL')} <br />
                                                        Level of Action: {tracking.rating}<br />
                                                        <strong>{capitalizeFirstLetter(tracking.approve_type)} by {tracking.approve_user.fullName}</strong>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </MainLayout>

        </>
    )
}

export default ReviewCase;