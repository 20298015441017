import apiConfig, { 
    getRequestHeaders
} from './base'

const { axiosInstance } = apiConfig

const baseApiURL = apiConfig.baseApiURL

export const uploadMedia = async ( args ) => {

    let result = {
        success: false
    }

    const requestHeaders = getRequestHeaders()

    try {
        const { data } = await axiosInstance.post(`${baseApiURL}/medias`, args, requestHeaders)
        result = data
    }
    catch( e ) {
        result.message = e.message
    }

    return result
}