import React, { useEffect, useRef, useState } from 'react'
import MainLayout from '../../containers/MainLayout'
import {
    NCInput,
    NCSelect,
    NCDatePicker,
    NCFileInput
} from "../../components/Fields"
import { getAllCountryCodes } from "../../apis/countryCodes"
import { useCommonState } from "../../stores/common";
import { useForm, Controller } from "react-hook-form";
import { mobileLookup } from "../../apis/contacts";
import Swal from "sweetalert2";
import { Spinner } from "react-bootstrap";
import { addLocalUsers } from "../../apis/localUsers";
import { useNavigate } from 'react-router-dom';
import { capitalize } from '../../utils';
import { getAllMinistries } from '../../apis/ministries';
import { NCC_RS_ROLES } from '../../utils/consts';

const Loading = ({ loading }) => {

    if (loading) {
        return (
            <Spinner size="sm" variant="info" animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        )
    }
    return ''
}


const AddNewLocalUsers = () => {

    const navigate = useNavigate()

    const formMethods = useForm()

    const {
        register,
        control,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors, isDirty, touchedFields },
    } = formMethods;

    const requiredOptions = {
        required: {
            value: true,
            message: "This field is required",
        },
    };
    const allowedPerms = ['View']
    const [isRendering, setIsRedering] = useState(false)
    const [showRole, setShowRoles] = useState(false)
    const [showMinistry, setShowMinistry] = useState(false)
    const [roleError, setRoleError] = useState(false)
    const [ministryError, setMinistryError] = useState(false)
    const [ministriesList, setMinistriesList] = useState([]);
    const countryCodesRef = useRef([])
    const dataLocalUsers = useRef()
    const commonStore = useCommonState()
    
    useEffect(() => {

        if (commonStore?.currentRole !== 'Admin') {
            navigate('/no-permission/')
        }

    }, [commonStore?.currentRole])

    useEffect(() => {
        _getAllCountryCodes()
    }, [])

    useEffect(() => {
        // if (isRendering) {
        //     setTimeout(() => {
        //         setIsRedering(false)
        //     }, [1000])
        // }
    }, [isRendering])

    useEffect(() => {

    }, [isRendering])

    /**
     * #48075 - NRC-178 - [Enhancement] - Manage user
     */
    useEffect(() => {
        _getMinistries()
    }, [commonStore?.ssoTokens])

    /**
     * #48075 - NRC-178 - [Enhancement] - Manage user
     */
    const _getMinistries = async () => {
        let _ministriesList = [];

        const ministries = await getAllMinistries();
        if (ministries.success) {
            const filtered = filterActiveItems(ministries.data)
            _ministriesList = filtered.map((i) => {
                return { value: i.id, label: i.value };
            })
        }
        setMinistriesList(_ministriesList);
    };

    /**
     * #48075 - NRC-178 - [Enhancement] - Manage user
     */
    const _onRoleChanged = (value) => {
        setShowMinistry(value === NCC_RS_ROLES.APPROVER.id);
    }

    /**
     * #48075 - NRC-178 - [Enhancement] - Manage user
     */
    const onChangeMinistries = (val) => {
        setValue('approverMinistries', val)
    }

    const filterActiveItems = (items) => {
        return items.filter(i => i.isActive === true)
    }

    const _getAllCountryCodes = async () => {
        setIsRedering(true)
        const countryCodes = await getAllCountryCodes();
        if (countryCodes.success) {
            const filtered = filterActiveItems(countryCodes.data)
            countryCodesRef.current = filtered.map((i) => {
                return { value: i.id, label: `${i.value} (+${i.id})` };
            })
        } else {
            countryCodesRef.current = []
        }
        setIsRedering(false)
    }

    const resetContact = () => {
        // setCurrentContact(null)
        setValue('fullName', '')
    }

    const searchContact = async (evt) => {
        const contactNumberCountryCode = getValues('contactNumberCode')
        const contactNumber = getValues('contactNumber')
        if (isRendering || !contactNumber || !contactNumberCountryCode) {
            return
        }
        setIsRedering(true)

        // if (process.env.REACT_APP_FAKE_ACCOUNT) {
        // 	try {
        // 		const contact = JSON.parse(process.env.REACT_APP_FAKE_ACCOUNT)
        // 		// setCurrentContact(contact)
        // 		setValue('full_name', contact.FullName)
        // 	}
        // 	catch (error) {
        // 		console.log('=JSON-parse-ERROR=', error)
        // 	}
        // }
        // else {

        // let chker = await checkDuplicatedPhone(countryCode, phoneNumber)
        let chker
        // if (chker && chker?.success) {
        const contact = await mobileLookup({
            mobile: contactNumber,
            countryCode: `+${contactNumberCountryCode}`
        })
        if (contact?.success === false) {
            let message = { title: '', type: 'warning', message: '<p>Contact not found in Noah.</p>' }
            let result = await Swal.fire({
                icon: message.type,
                title: message.title,
                html: message.message,
            }).then((value) => {
                return value
            })
            setShowRoles(false)

        }
        else if (chker?.is_dupplicatated) {
            let message = { title: '', type: 'warning', message: '<p>This mobile number has an existing record.</p>' }
            setShowRoles(false)

        } else if (contact?.success === false) {
            let message = { title: '', type: 'warning', message: '<p>Contact not found in Noah.</p>' }
            setShowRoles(false)

        } else {
            // setCurrentContact(contact.data)
            setValue('fullName', contact?.data?.FullName)
            setValue('userId', contact?.data?.UserId)
            setShowRoles(true)
            dataLocalUsers.current = contact?.data
        }
        // }

        // }
        setIsRedering(false)
    }

    const handleCreateNewUser = async (data) => {
        const role = getValues('roles')
        const contactNumberCode = getValues('contactNumberCode')
        const contactNumber = getValues('contactNumber')
        if (!role) {
            formMethods.setError('roles', { type: 'manual', message: 'Please select role' })
            return
        }
        if (!contactNumberCode || !contactNumber) {
            return
        }
        let approverMinistries = [];
        if (role === NCC_RS_ROLES.APPROVER.id) {
            const _approverMinistries = getValues('approverMinistries');
            approverMinistries = _approverMinistries.map(i => i.value);
            data['ministries'] = approverMinistries;
        }
        try {
            const status = await addLocalUsers(data);
            // console.log('=stat9s', status)
            if (status.success) {
                Swal.fire({
                    icon: "success",
                    text: "User has been created!",
                    willClose: () => {
                        navigate('/dashboard/manage-users')
                    }
                })
            }
            else {
                Swal.fire({
                    icon: "error",
                    text: capitalize(status.message),
                })
            }
        }
        catch (err) {
            console.log('=Error creating user=', err)
        }
    }

    return (
        <MainLayout allowedPerms={allowedPerms}>
            <div className="content-wrapper">
                <h1 className="mt-5 mb-5 font-weight-bold">Add New User</h1>
                <p>Please search user by mobile number first then tag the role if record found</p>
            </div>
            <form
                onSubmit={handleSubmit(handleCreateNewUser)}
                className="g-3 needs-validation"
                noValidate
            >
                <div className="case-form-wrapper">
                    <div className={`row ncc-mrb-px-30`}>
                        <div className="col-md-2">
                            <span className="span-text">Contact Number</span>
                            <span className="required-mark">*</span>
                        </div>
                        <div className="col-md-10 ncc-pr-0">
                            <div className="row">
                                <div className="col-md-3">
                                    <NCSelect
                                        options={countryCodesRef.current}
                                        name={`contactNumberCode`}
                                        isClearable={true}
                                        isSearchable={true}
                                        isMulti={false}
                                        requiredOptions={requiredOptions}
                                        control={control}
                                        errors={errors}
                                        isDisabled={false}
                                        onChange={resetContact}
                                        // onBlur={() => _checkDupPhone()}
                                        onBlur={searchContact}
                                    />
                                </div>
                                <div className="col-md-9">
                                    <NCInput
                                        name={`contactNumber`}
                                        requiredOptions={requiredOptions}
                                        control={control}
                                        errors={errors}
                                        register={register}
                                        className={`ncc-pl-px-10`}
                                        // isDisabled={isDisabled}
                                        onChange={resetContact}
                                        // onBlur={() => _checkDupPhone()}
                                        onBlur={searchContact}
                                    />
                                    {/* {errorDupPhone && <span className="text-danger">This mobile number has an existing record.</span>} */}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="mb-3 row">
                        <div className="col-md-2">
                            <span className="span-text">Full Name</span>
                            <span className="required-mark">*</span>
                        </div>
                        <div className="col-md-10 ncc-pr-0">
                            <NCInput
                                name={`fullName`}
                                requiredOptions={requiredOptions}
                                // errors={errors}
                                control={control}
                                register={register}
                                className={`ncc-pl-px-10`}
                                isDisabled={true}
                            />
                            <NCInput
                                name={`userId`}
                                requiredOptions={{}}
                                control={control}
                                register={register}
                                className={`ncc-pl-px-10`}
                                type="hidden"
                                required={false}
                            />
                        </div>
                    </div>

                    {showRole &&
                        <div className="mb-3 row">
                            <div className="col-md-2">
                                <span className="span-text">Role</span>
                                <span className="required-mark">*</span>
                            </div>
                            <div className="col-md-10 ncc-pr-0 custom-form">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input checkbox-style" type="radio" {...register("roles")} id={NCC_RS_ROLES.REQUESTOR.id} value={NCC_RS_ROLES.REQUESTOR.id} onChange={() => _onRoleChanged(NCC_RS_ROLES.REQUESTOR.id)} />
                                    <label className="form-check-label" htmlFor="Requestor">{NCC_RS_ROLES.REQUESTOR.label}</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input checkbox-style" type="radio" {...register("roles")} id={NCC_RS_ROLES.APPROVER.id} value={NCC_RS_ROLES.APPROVER.id} onChange={() => _onRoleChanged(NCC_RS_ROLES.APPROVER.id)} />
                                    <label className="form-check-label" htmlFor="Approver">{NCC_RS_ROLES.APPROVER.label}</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input checkbox-style" type="radio" {...register("roles")} id={NCC_RS_ROLES.VIEWER.id} value={NCC_RS_ROLES.VIEWER.id} onChange={() => _onRoleChanged('Viewer')} />
                                    <label className="form-check-label" htmlFor="Viewer">{NCC_RS_ROLES.VIEWER.label}</label>
                                </div>
                                {roleError && <div className="text-danger">Please select role</div>}

                            </div>
                        </div>
                    }

                    {showMinistry && ministriesList.length > 0 &&
                        <div className="row">
                            <div className="col-md-2">
                                <span className="span-text">Ministry</span>
                                <span className="required-mark">*</span>
                            </div>
                            <div className="col-md-10 ncc-pr-0 custom-form">
                                <NCSelect
                                    options={ministriesList}
                                    name={`approverMinistries`}
                                    isClearable={true}
                                    isSearchable={true}
                                    isMulti={true}
                                    requiredOptions={requiredOptions}
                                    control={control}
                                    errors={errors}
                                    isDisabled={false}
                                    onChange={onChangeMinistries}
                                />
                                {ministryError && <div className="text-danger">Please select ministries</div>}
                            </div>
                        </div>
                    }

                    <div className='d-flex justify-content-center'>
                        <button disabled={isRendering} className={`btn button-action button-save m-3`} type="submit">
                            Submit&nbsp;
                            <Loading loading={isRendering} />
                        </button>
                    </div>

                </div>
            </form>
        </MainLayout >
    )
}

export default AddNewLocalUsers