import { Outlet } from "react-router-dom"

const Dashboard = ( props ) => {
    
    return (
        <>
            <span>Dashboard</span>
            <Outlet />
        </>
    )
}

export default Dashboard