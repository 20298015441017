import { useEffect } from 'react'
import { useCommonState } from "../stores/common"

const NoPermission = () => {

    const commonStore = useCommonState()

    useEffect(() => {
        if ( commonStore.currentUserProfile ) {
            // navigate('/dashboard/cases')
        }
    }, [commonStore.currentUserProfile])

    return (
        <>
            <div className="ncc-w-100 ncc-h-100 dfl-c-jctr-alctr ncc-h-vh-100">
                <div className="ncc-mrb-px-25">
                    <h1 className="text-center"><b>Access Denied</b></h1>
                </div>
                <div className="ncc-mrb-px-25">
                    <img className="" src={"/images/NCC-No-Permission.png"} alt="NCC" />
                </div>
                <div className="ncc-w-px-563 dfl-r-jctr-alctr">
                    <span className="ncc-fs-30 text-align-center">Sorry, but you do not have <br /> the permission to access this page.</span>
                </div>
            </div>
        </>
    )
    
}

export default NoPermission